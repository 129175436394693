
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DialogBoxType, DialogBoxService, DialogBoxData } from 'dialog-box';
import { HybridsSocketListenerService, SocketAction, SocketAddress, SocketMessage } from 'hybrids-socket';
import { Device, EcmoMode, ErrorType } from 'hybrids-config';
import { EventManagerService } from 'event-manager';
import { PreasureType } from 'hvita-common';
import { Network } from 'network';
import { HVitaEvent } from '../enums/hvita-event.enum';
import { HVitaConfigService } from './hvita-config.service';
import { HVitaSocketService } from './hvita-socket.service';
import { HVitaSimulationService } from './hvita-simulation.service';
import { SocketEvent } from 'socket';
import { HVitaLanguageService } from './hvita-language.service';
import { HVitaSimulationSelectorService } from 'hvita-simulation-selector';
import { HVitaColorService } from './hvita-color.service';
import { HVitaParameterService } from './hvita-parameter.service.';
import { HVitaTemplateService } from './hvita-template.service';
import { PatientMonitorService } from './patient-monitor.service';


/**
 * Gestiona las conexiones al socket service
 */
@Injectable({
  providedIn: 'root'
})
export class HVitaSocketListenerService extends HybridsSocketListenerService
{
  firstIntent: boolean; //indica si es el primer intento de conexión al socket service

  constructor(private dialogService:DialogBoxService,
              private router: Router,
              private configService:HVitaConfigService,
              private simulationService:HVitaSimulationService,
              private selectorService:HVitaSimulationSelectorService,
              private languageService:HVitaLanguageService,
              private colorService:HVitaColorService,
              private parameterService:HVitaParameterService,
              private templateService:HVitaTemplateService,
              private monitorService:PatientMonitorService,
              hybridsSocketService:HVitaSocketService,
              eventManagerService: EventManagerService)
  {

    super(eventManagerService, hybridsSocketService);
    this.firstIntent = true;

    this.eventManagerService.addEvent<boolean>(HVitaEvent.SOCKET_LISTENER_VITABOX);
  }

  /**
   * Establece las acciones a realizar cuando se recibe una acción del socket
   */
  public setSocketReceived(request: SocketMessage): void
  {
    if(request.action === SocketAction.ENABLED_DEVICE)
    {
      //un dispositivo ha sido conectado
      //no es necesaria una respuesta
      //pasamos directamente el valor
      this.addDevice(request.data);
    }
    else if(request.action === SocketAction.DISABLED_DEVICE)
    {
      //un dispositivo ha sido desconectado
      //no es necesaria una respuesta
      //pasamos directamente el valor
      this.removeDevice(request.data);
    }
    else if(request.from.device === Device.STUDENT)
    {
      //comprobamos el action de la solicitud
      //y damos una respuesta en función del tipo
      switch(request.action)
      {
        //Student solicita el idioma actual
        //pasamos toda la peticion para poder responder
        case SocketAction.LANGUAGE :
          this.languageService.sendCurrentLanguage(request);
          break;

        //Student solicita la lista de colores
        //pasamos toda la peticion para poder responder
        case SocketAction.COLORS_LIST :
          this.hybridsSocketService.send(request.action!, request.from!, this.colorService.colors, request.id);
          break;

        //Student solicita la lista de datos de curvas (monitor de paciente)
        //pasamos toda la peticion para poder responder
        case SocketAction.WAVES_LIST :
          this.monitorService.sendWaveList(request);
          break;

        //Student solicita la lista de damping (monitor de paciente)
        //pasamos toda la peticion para poder responder
        case SocketAction.DAMPINGS_LIST :
          this.monitorService.sendDampingList(request);
          break;

        //Student solicita la lista de ritmos (monitor de paciente)
        //pasamos toda la peticion para poder responder
        case SocketAction.RHYTHMS_LIST:
          this.monitorService.sendRhytmsList(request);
          break;

        //Student solicita la lista de vistas (monitor de paciente)
        //pasamos toda la peticion para poder responder
        case SocketAction.PATIENT_VIEW_LIST:
          this.monitorService.sendPatientViewList(request);
          break;

        //Student solicita la lista de variables de derivación (monitor de paciente)
        //pasamos toda la peticion para poder responder
        case SocketAction.DERIVATION_VARIABLES_LIST :
          this.monitorService.sendDerivationVariablesList(request);
          break;

        //Student solicita la lista de tests de un escenario
        //pasamos toda la peticion para poder responder
        case SocketAction.TEST_LIST :
          this.simulationService.sendTestList(request);
          break;

        //Student solicita la lista de plantillas
        //pasamos toda la peticion para poder responder
        case SocketAction.TEMPLATES_LIST :
          this.templateService.sendTemplateListStudent(request);
          break;

        //Student solicita el modo de Ecmo
        //pasamos toda la peticion para poder responder
        case SocketAction.ECMO_MODE :
          this.configService.sendEcmoMode(request);
          break;

        //Student solicita el modo de interacción del alumno
        //pasamos toda la peticion para poder responder
        case SocketAction.INTERACTIVE_MODE :
          this.configService.sendInteractiveMode(request);
          break;

        //Student solicita la url del servidor web de Teacher para la reproducción de imágenes y vídeos
        //pasamos toda la peticion para poder responder
        case SocketAction.WEB_SERVER_TEACHER :
          this.configService.sendWebServerTeacherUrl(request);
          break;

        //Student solicita los datos de la simulación en proceso
        //pasamos toda la peticion para poder responder
        case SocketAction.STAGE :
          this.parameterService.sendCurrentParameters(request);
          break;

        //Student envía cambios en un parámetro
        //no es necesaria una respuesta
        //pasamos directamente el valor
        case SocketAction.PARAMETER :
          this.parameterService.changeParameterFromServer(request.data, request.from.device);
          break;

        //Student envía cambios en un parámetro
        //no es necesaria una respuesta
        //pasamos directamente el valor
        case SocketAction.CANCEL_TIMER :
          this.parameterService.cancelTimerFromStudent(request.data);
          break;

        //Student envia órden de modificar el ritmo del monitor de paciente
        //no es necesaria una respuesta
        //pasamos directamente los datos
        case SocketAction.CHANGE_RHYTHM :
          this.monitorService.changeListValues(request.data);
          break;

        //Student envia órden de modificar las vistas del monitor de paciente
        //no es necesaria una respuesta
        //pasamos directamente los datos
        case SocketAction.CHANGE_PATIENT_VIEW :
          this.monitorService.changePatientViews(request.data);
          break;


        //Student envia petición de estado de las alamas
        case SocketAction.MUTE_STATUS :
          this.simulationService.sendMuteStatus(request);
          break;

        //Student solicita el modo de ventilación
        case SocketAction.GET_VENTILATION_MODE:
          this.simulationService.sendVentilationMode(request);
          break;

        //Student envía orden de modificar el modo de ventilación
        case SocketAction.SET_VENTILATION_MODE:
          this.simulationService.setVentilationMode(request.data, false);
          break;
      }
    }
    else if(this.configService.device === request.from.device &&
           ((this.configService.ecmoMode === EcmoMode.REAL && this.configService.device === Device.VITABOX) ||
            (this.configService.ecmoMode === EcmoMode.REPLICA && this.configService.device === Device.REPLICA)))
    {
      //comprobamos el action de la solicitud
      //y damos una respuesta en función del tipo
      switch(request.action)
      {
        //Control notifica que se ha apagado la Raspberry PI
        // case SocketAction.SHUTDOWN :
        //   this.setActionLostControl();
        //   break;

        //Control notifica un cambio de valores
        case SocketAction.CHANGE_VALUE :
          this.simulationService.changeControlValues(request.data);
          break;

        //Control notifica un cambio de batería
        case SocketAction.CHANGE_BATTERY :
          this.simulationService.changeBattery(request.data);
          break;

        //Control solicita el idioma actual
        //pasamos toda la peticion para poder responder
        case SocketAction.LANGUAGE :
          this.languageService.sendCurrentLanguage(request);
          break;

        //Control solicita los datos de la simulación en proceso
        //pasamos toda la peticion para poder responder
        case SocketAction.STAGE :
          this.parameterService.sendCurrentParameters(request);
          break;

        //Control solicita el modo de Ecmo
        //pasamos toda la peticion para poder responder
        case SocketAction.ECMO_MODE :
          this.configService.sendEcmoMode(request);
          break;


        //Una petición de resupuesta devuelta de control ha sido pasada por alto
        //emitimos un evento que obligue a evaluar las peticiones pendientes
        default:
            this.eventManagerService.emitEvent(SocketEvent.RESPONSE, request);
          break;
      }
    }
  }

  /**
   * Establece las acciones a realizar durante la desconexión del socket
   */
  protected setSocketDisconnected(): void
  {
    this.configService.studentEnabled = false;
    this.configService.vitaboxEnabled = false;
    this.configService.replicaEnabled = false;

    this.eventManagerService.emitEvent(HVitaEvent.SOCKET_LISTENER_VITABOX, false);

    this.setActionLostControl(EcmoMode.VIRTUAL, this.configService.device !== Device.NONE ? this.configService.simulationDevice : undefined);

    this.firstIntent = false;
  }


  /**
   * Comprueba los dispositivos conectados al socket service
   */
  protected getConnectedDevices(): void
  {
    //Enviamos una petición que espera respuesta
    this.hybridsSocketService.sendAndRetrive(SocketAction.ENABLED_DEVICES_LIST, this.hybridsSocketService.addresses.server).then((response:any) =>
    {
      //guardamos la lista de dispositivos conectados
      this.devices = (response.data instanceof Array) ? response.data as SocketAddress[] : new Array<SocketAddress>();

      this.configService.studentEnabled = false;
      this.configService.vitaboxEnabled = false;
      this.configService.replicaEnabled = false;

      this.devices.map(device =>
      {
        switch(device.device)
        {
          case Device.STUDENT: this.configService.studentEnabled = true; break;
          case Device.VITABOX: this.configService.vitaboxEnabled = true; break;
          case Device.REPLICA: this.configService.replicaEnabled = true; break;
        }
      });

      //comprobamos si Vitabox o Replica está conectado
      if(this.configService.vitaboxEnabled || this.configService.replicaEnabled)
      {
        //si es el primer intento establecemos automáticamente
        //el modo de simulación en Real y mostramos la pantalla Home
        if(this.firstIntent)
        {
          //establecemos el modo de simulación
          if(this.configService.ecmoMode === EcmoMode.REAL && this.configService.vitaboxEnabled)
          {
            this.configService.setEcmoMode(EcmoMode.REAL);
          }
          else if(this.configService.ecmoMode === EcmoMode.REPLICA && this.configService.replicaEnabled)
          {
            this.configService.setEcmoMode(EcmoMode.REPLICA);
          }
          else
          {
            const mode = (this.configService.vitaboxEnabled) ? EcmoMode.REAL : ((this.configService.replicaEnabled) ? EcmoMode.REPLICA : EcmoMode.VIRTUAL);
            this.configService.setEcmoMode(mode);
          }

          this.router.navigate(['/', (this.configService.ageMode ? 'home' : 'age-mode') ]);
        }
        else
        {
          if(this.selectorService.simulationPlaying)
          {
            if(this.configService.simulationDevice === Device.VITABOX && this.configService.vitaboxEnabled)
            {
              this.configService.device = Device.VITABOX;
              this.configService.simulationDevice = Device.VITABOX;
              this.showRestoredControl(Device.VITABOX);
            }
            else if(this.configService.simulationDevice === Device.REPLICA && this.configService.replicaEnabled)
            {
              this.configService.device = Device.REPLICA;
              this.configService.simulationDevice = Device.REPLICA;
              this.showRestoredControl(Device.REPLICA);
            }
            else if(this.configService.vitaboxEnabled)
            {
              this.configService.device = Device.VITABOX;
              this.configService.simulationDevice = Device.VITABOX;
              this.showRestoredControl(Device.VITABOX);
            }
            else if(this.configService.replicaEnabled)
            {
              this.configService.device = Device.REPLICA;
              this.configService.simulationDevice = Device.REPLICA;
              this.showRestoredControl(Device.REPLICA);
            }
          }
          else
          {
            if(this.configService.vitaboxEnabled && this.configService.device === Device.VITABOX)
              this.configService.device = Device.VITABOX;
            else if(this.configService.replicaEnabled && this.configService.device === Device.REPLICA)
              this.configService.device = Device.REPLICA;
            else if(this.configService.vitaboxEnabled)
              this.configService.device = Device.VITABOX;
            else if(this.configService.replicaEnabled)
              this.configService.device = Device.REPLICA;
          }
        }
      }
      else
      {
        //respondemos a la pérdida de control
        this.setActionLostControl(EcmoMode.VIRTUAL);
      }

      this.firstIntent = false;
    });
  }


  /**
   * Establece las acciones a realizar después de añadir un dispositivo
   * @param device dispositivo añadido
   */
  protected setAddDevice(device: Device): void
  {
    switch(device)
    {
      case Device.STUDENT:
        this.configService.studentEnabled = true;
        break;
      case Device.VITABOX:
        this.configService.vitaboxEnabled = true;

        if(this.configService.device === Device.NONE)
        {
          this.configService.device = Device.VITABOX;

          if(this.selectorService.simulationPlaying)
            this.configService.simulationDevice = Device.VITABOX;
        }

        this.showRestoredControl(Device.VITABOX);
        break;
      case Device.REPLICA:
        this.configService.replicaEnabled = true;

        if(this.configService.device === Device.NONE)
        {
          this.configService.device = Device.REPLICA;

          if(this.selectorService.simulationPlaying)
            this.configService.simulationDevice = Device.REPLICA;
        }

        this.showRestoredControl(Device.REPLICA);
        break;
    }
  }


  /**
   * Establece las acciones a realizar después de eliminar un dispositivo
   * @param device dispositivo eliminado
   */
  protected setRemoveDevice(device: Device): void
  {
    switch(device)
    {
      case Device.STUDENT:
          this.configService.studentEnabled = false;
        break;

      case Device.VITABOX:
        this.configService.vitaboxEnabled = false;
        this.eventManagerService.emitEvent(HVitaEvent.SOCKET_LISTENER_VITABOX, false);

        if(this.configService.device === device)
        {
          this.setActionLostControl(EcmoMode.VIRTUAL, device);
        }
        break;

      case Device.REPLICA:
        this.configService.replicaEnabled = false;

        if(this.configService.device === device)
        {
          this.setActionLostControl(EcmoMode.VIRTUAL, device);
        }
        break;
    }
  }


  /**
   * Establece la acción a realizar tras la pérdida de contacto con Control
   */
  private setActionLostControl(mode: EcmoMode, device: Device | null = null)
  {
    if(this.firstIntent)
    {
      //mostramos la pantalla de error
      this.router.navigate([ '/', 'error', ErrorType.SOCKET ]);
    }
    else if(this.selectorService.simulationPlaying && this.configService.simulationDevice === device)
    {
      this.hybridsSocketService.clearRequests();

      //mostramos aviso al usuario de que se ha perdido la conexión y se ha establecido
      //el modo de simulación en modo Virtual
      this.dialogService.show(new DialogBoxData({
        type: DialogBoxType.ALERT,
        message: 'conexion_perdida'
      }));
    }

    //establecemos el modo de simulación en virtual
    this.configService.device = Device.NONE;
    this.configService.setEcmoMode(mode);
    this.simulationService.resetBatteryStats();
  }

  /**
   * Muestra un aviso cuando se recupera la conexión con Control
   * si hay una simulación en progreso
   */
  private showRestoredControl(device: Device)
  {
    // si hay una simulación en progreso se muestra un alert
    // ofreciendo al usuario la posibilidad de cambiar el modo
    // de simulación a modo real
    if(this.selectorService.simulationPlaying && (this.configService.simulationDevice === device || this.configService.simulationDevice === Device.NONE))
    {
      this.dialogService.show(new DialogBoxData({
        type: DialogBoxType.CONFIRM,
        message: (device === Device.VITABOX) ? 'conexion_restaurada_vitabox' : 'conexion_restaurada_replica',
        acceptCallback: async () =>
        {
          this.configService.simulationDevice = device;
          this.configService.setEcmoMode((device === Device.VITABOX) ? EcmoMode.REAL : EcmoMode.REPLICA);
          this.simulationService.setPreasureType((this.configService.ecmoMode === EcmoMode.REPLICA) ? PreasureType.VALUE : this.simulationService.stage!.idPreasureType!);
          this.simulationService.getControlValues();
          this.simulationService.getBatteryStats();
        }
      }));
    }
  }

  protected setRetryConnection()
  {
    Network.getNetworkStatus().then(status =>
    {
      if(status)
        this.hybridsSocketService.timeOutRetry = setTimeout(() => this.hybridsSocketService.openConnection(), this.hybridsSocketService.interval);
    });
  }
}
