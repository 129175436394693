import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { HVitaParameterToValuePipe, Session, SessionAction } from "hvita-common";
import { HVitaLogBaseService } from 'hvita-log';
import { RepositoryService, Query, Table, Condition, ConditionOperator, Field } from "sqlite";
import { HVitaScenarioService } from "./hvita-scenario.service";

/**
 * Gestiona los colores de la aplicación
 */
@Injectable({
  providedIn: 'root'
})
export class HVitaLogService extends HVitaLogBaseService
{
  constructor(translateService:TranslateService,
              scenarioService:HVitaScenarioService,
              parameterToValuePipe: HVitaParameterToValuePipe,
              private repositoryService:RepositoryService)
  {
    super(translateService, scenarioService, parameterToValuePipe);
  }

  getSessionsBetweenDates(from: Date, to: Date): Promise<Session[]>
  {
    const table = new Table('Sessions',
      [
        new Field('inserted',
        [
          new Condition(ConditionOperator.GREATER_EQUAL, from.toDbDate()),
          new Condition(ConditionOperator.LESS, to.toDbDate())
        ]),
        new Field('idSession', undefined, true)
      ]);

      return this.repositoryService.exeQuery(new Query([table]));
  }

  getSessionActions(idSession: number): Promise<SessionAction[]>
  {
    const table = new Table('SessionsActions',
      [
        new Field('idSession', [new Condition(ConditionOperator.EQUAL, idSession)]),
        new Field('idAction', undefined, true)
      ]);

      return this.repositoryService.exeQuery(new Query([table]));
  }

  async removeSessions(sessions: number[]): Promise<void>
  {
    await this.repositoryService.deleteMany('SessionsActions', 'idSession', sessions);
    await this.repositoryService.deleteMany('Sessions', 'idSession', sessions);
  }

  insertSession(table: string, fields: string[], values: any[], idField: string): Promise<any>
  {
    return new Promise((resolve, reject) =>
    {
      this.repositoryService.insert(table, fields, values).then(() =>
      {
        if(idField)
          this.repositoryService.getLastId(table, idField).then(id => resolve(id)).catch(err => reject(err));
        else
          resolve(0);
      })
      .catch(err => reject(err));
    });
  }
}
