import { EventManagerService } from 'event-manager';
import { Injectable } from "@angular/core";
import { HVitaScenarioTeacherService } from "hvita-scenario";
import { Scenario, Parameter, ParameterValue, ParameterVentilation, ParameterWave, ParameterBoundary, AgeMode } from "hvita-common";
import { RepositoryService, Query, Table, Condition, ConditionOperator, Field, OrderDirection } from "sqlite";
import { HVitaConfigService } from './hvita-config.service';

/**
 * Gestiona los colores de la aplicación
 */
@Injectable({
  providedIn: 'root'
})
export class HVitaScenarioService extends HVitaScenarioTeacherService
{
  constructor(eventManagerService: EventManagerService,
              private configService:HVitaConfigService,
              private repositoryService:RepositoryService)
  {
    super(eventManagerService);
  }

  getScenario(idScenario?: number): Promise<Scenario>
  {
    return new Promise<Scenario>((resolve, reject) =>
    {
      let field = (idScenario) ? new Field('idScenario', [new Condition(ConditionOperator.EQUAL, idScenario)])
                               : new Field('isBasal', [ new Condition(ConditionOperator.EQUAL, true) ]);

      let table = new Table('Scenarios', [ new Field('idAgeMode', [new Condition(ConditionOperator.EQUAL, this.configService.ageMode)]), field ]);

      //obtenemos el escenario
      this.repositoryService.exeQuery(new Query([table])).then(data =>
      {
        const scenario = data[0];

        field = new Field('idScenario', [new Condition(ConditionOperator.EQUAL, scenario.idScenario)]);
        table = new Table('Tests', [ field ]);

        //rellenamos test y stages
        this.repositoryService.exeQuery(new Query([table])).then(tests =>
        {
          scenario.tests = tests;

          table = new Table('Stages', [ field ]);

          this.repositoryService.exeQuery(new Query([table])).then(async stages =>
          {
            scenario.stages = stages;

            //rellenamos parámetros y valores establecidos por el usuario
            for (const stage of scenario.stages)
            {
              field = new Field('idStage', [new Condition(ConditionOperator.EQUAL, stage.idStage)]);
              table = new Table('StagesParameters', [ field ]);

              stage.parameters = await this.repositoryService.exeQuery(new Query([table]));

              for (const parameter of stage.parameters)
              {
                field = new Field('idStageParameter', [new Condition(ConditionOperator.EQUAL, parameter.idStageParameter)]);
                table = new Table('StagesParametersValues', [ field ]);

                parameter.parametersValues = await this.repositoryService.exeQuery(new Query([table]));
              }
            }

            resolve(scenario);
          })
          .catch(err => reject(err));
        })
        .catch(err => reject(err));
      })
      .catch(err => reject(err));
    });
  }

  getScenarios(): Promise<Scenario[]>
  {
    return new Promise<Scenario[]>((resolve, reject) =>
    {
      let field = [
        new Field('isBasal', [new Condition(ConditionOperator.EQUAL, false)]),
        new Field('idAgeMode', [new Condition(ConditionOperator.EQUAL, this.configService.ageMode)]),
        new Field('inserted', undefined, true, OrderDirection.DESC)
      ];

      let table = new Table('Scenarios', field);

      this.repositoryService.exeQuery(new Query([ table ])).then(scenarios =>
      {
        let ids:number[] = [];

        scenarios.map(scenario => ids.push(scenario.idScenario));

        field = [ new Field('idScenario', [new Condition(ConditionOperator.IN, ids.join(', '))]) ];
        table = new Table('Tests', field);

        this.repositoryService.exeQuery(new Query([ table ])).then(tests =>
        {
          field = [
            new Field('idScenario', [new Condition(ConditionOperator.IN, ids.join(', '))]),
            new Field('position', undefined, true)
          ];

          table = new Table('Stages', field);

          this.repositoryService.exeQuery(new Query([ table ])).then(stages =>
          {
            ids = [];

            stages.map(stage => ids.push(stage.idStage));

            field = [ new Field('idStage', [new Condition(ConditionOperator.IN, ids.join(', '))]) ];
            table = new Table('StagesParameters', field);

            this.repositoryService.exeQuery(new Query([ table ])).then(stagesParameters =>
            {
              ids = [];

              stagesParameters.map(stageParameter => ids.push(stageParameter.idStageParameter));

              field = [ new Field('idStageParameter', [new Condition(ConditionOperator.IN, ids.join(', '))]) ];
              table = new Table('StagesParametersValues', field);

              this.repositoryService.exeQuery(new Query([ table ])).then(stagesParametersValues =>
              {
                //construimos los objetos
                for (const stage of stages)
                {
                  stage.parameters = stagesParameters.filter((m:any) => m.idStage === stage.idStage);

                  for (const parameter of stage.parameters)
                    parameter.parametersValues = stagesParametersValues.filter(m => m.idStageParameter === parameter.idStageParameter);
                }

                for (const scenario of scenarios)
                {
                  scenario.stages = stages.filter(m => m.idScenario === scenario.idScenario);
                  scenario.tests = tests.filter(m => m.idScenario === scenario.idScenario);
                }

                resolve(scenarios);
              })
              .catch(err => reject(err));
            })
            .catch(err => reject(err));
          })
          .catch(err => reject(err));
        })
        .catch(err => reject(err));
      })
      .catch(err => reject(err));
    });
  }

  getParameters(): Promise<Parameter[]>
  {
    return new Promise<Parameter[]>((resolve, reject) =>
    {
      this.repositoryService.exeQuery(new Query([new Table('Parameters')])).then(parameters =>
      {
        this.repositoryService.exeQuery(new Query([new Table('ParametersValues')])).then(values =>
        {
          let field = [ new Field('idAgeMode', [new Condition(ConditionOperator.EQUAL, AgeMode.NONE)])];

          this.repositoryService.exeQuery(new Query([new Table('ParametersBoundaries', field )])).then(baseBoundaries =>
          {
            field = [ new Field('idAgeMode', [new Condition(ConditionOperator.EQUAL, this.configService.ageMode)])];

            this.repositoryService.exeQuery(new Query([new Table('ParametersBoundaries', field )])).then(boundaries =>
            {
              // añadimos los boundaries
              for (const value of values)
              {
                const boundary:ParameterBoundary[] = boundaries.filter((m:ParameterBoundary) => m.idParameterValue === value.idParameterValue) ?? [];

                boundary.push(baseBoundaries.find((m:ParameterBoundary) => m.idParameterValue === value.idParameterValue));

                value.boundaries = boundary;
              }

              this.repositoryService.exeQuery(new Query([new Table('ParametersWaves')])).then(waves =>
              {
                this.repositoryService.exeQuery(new Query([new Table('ParametersVentilations')])).then(ventilations =>
                {
                  for (const parameter of parameters)
                  {
                    parameter.values = values.filter((m:ParameterValue) => m.idParameter === parameter.idParameter);
                    parameter.waves = waves.filter((m:ParameterWave) => m.idParameter === parameter.idParameter);
                    parameter.ventilations = ventilations.filter((m:ParameterVentilation) => m.idParameter === parameter.idParameter);
                  }

                  resolve(parameters);
                })
                .catch(err => reject(err));
              })
              .catch(err => reject(err));
            })
            .catch(err => reject(err));
          })
          .catch(err => reject(err));
        })
        .catch(err => reject(err));
      })
      .catch(err => reject(err));
    });
  }
}
