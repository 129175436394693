import { Injectable } from "@angular/core";
import { HVitaColorBaseService } from 'hvita-color';
import { Color } from "hvita-common";
import { Query, RepositoryService, Table } from "sqlite";

/**
 * Gestiona los colores de la aplicación
 */
@Injectable({
  providedIn: 'root'
})
export class HVitaColorService extends HVitaColorBaseService
{
  constructor(private repositoryService:RepositoryService)
  {
    super();
  }

  getColors(): Promise<Color[]>
  {
    return this.repositoryService.exeQuery(new Query([new Table('Colors')]));
  }
}
