import { HVitaSocketService } from './hvita-socket.service';
import { Injectable } from "@angular/core";
import { DialogBoxService } from "dialog-box";
import { EventManagerService } from "event-manager";
import { HVitaColorToNamePipe } from "hvita-color";
import { HVitaParameterTeacherService } from "hvita-parameter";
import { HVitaSimulationSelectorService } from "hvita-simulation-selector";
import { ModalService } from "modal";
import { HVitaLogService } from './hvita-log.service';
import { HVitaConfigService } from './hvita-config.service';
import { Parameter, PreasureType, ParameterType } from 'hvita-common';
import { Device, EcmoMode } from 'hybrids-config';
import { SocketAction, SocketMessage } from 'hybrids-socket';
import { HVitaColorService } from './hvita-color.service';
import { ModalBoxService } from 'modal-box';

/**
 * Gestiona los colores de la aplicación
 */
@Injectable({
  providedIn: 'root'
})
export class HVitaParameterService extends HVitaParameterTeacherService
{
  constructor(dialogBoxService: DialogBoxService,
              selectorService: HVitaSimulationSelectorService,
              modalBoxService: ModalBoxService,
              socketService: HVitaSocketService,
              logService: HVitaLogService,
              configService: HVitaConfigService,
              modalService: ModalService,
              eventManagerService: EventManagerService,
              colorToNamePipe: HVitaColorToNamePipe,
              colorService:HVitaColorService)
  {
    super(dialogBoxService,
          selectorService,
          modalBoxService,
          socketService,
          logService,
          configService,
          modalService,
          eventManagerService,
          colorToNamePipe,
          colorService);
  }

  /**
   * Envía un parámetro a Student
   * @param parameter Parámetro a enviar
   */
  override sendParameter(parameter: Parameter)
  {
    super.sendParameter(parameter);

    //notificamos a replica
    if(this.config.ecmoMode === EcmoMode.REPLICA)
      this.socketService.send(SocketAction.PARAMETER, this.socketService.addresses.replica, parameter);
  }

  /**
  * Envia una petición de establecimiento de valores a Control y espera respuesta
  * @param request SocketMessage
  * @returns Promise<any>
  */
  override addToRequestControllers(request:SocketMessage)
  {
    if(this.socketService.enabledConnection)
      super.addToRequestControllers(request);
  }

  setValuesController(parameter:Parameter)
  {
    if(this.checkSelectedPreasure(parameter))
    {
      this.updateValues(parameter); //actualizamos los valores
      this.sendControl(parameter); //si es un parámetro del controlador enviamos la acción a la controladora
    }
    else
    {
      parameter.nextStageParameter!.parametersValues = parameter.stageParameter!.parametersValues; //reseteamos los valores next para invalidarlos
      this.invalidPreasure = parameter.key!; //almacenamos el error
      this.sendControl(parameter); //enviamos la acción
    }
  }


  checkSelectedPreasure(parameter:Parameter)
  {
    //comprobamos si se intenta establecer una presión válida
    /*
      Si valor pvent objetivo <  pvent actual  y no hay oclusión  Si se envía
      Si valor pvent objetivo < pvent actual y hay oclusión       Si se envía
      Si valor pvent objetivo >  pvent actual  y no hay oclusión  No se envia
      Si valor pvent objetivo > pvent actual  y hay oclusión      Si se envía

      Si valor part objetivo > part actual y no hay oclusión  Si se envía
      Si valor part objetivo > part actual y hay oclusión     Si se envía
      Si valor part objetivo < part actual y no hay oclusión  No se envia
      Si valor part objetivo < part actual  y hay oclusión    Si se envía
    */

    const current = parameter.stageParameter!.parametersValues![0].value!,
          next = parameter.nextStageParameter!.parametersValues![0].value!;

    if(this.config.ecmoMode === EcmoMode.REAL && this.config.config.preasureType === PreasureType.VALUE)
    {
      //pven
      if(parameter.key === 'p1' && this.currentOcclusionP1 === 0 && next > current)
        return false;

      //part
      if(parameter.key === 'p3' && this.currentOcclusionP3 === 0 && next < current)
        return false;
    }

    return true;
  }


  /**
  * Responde a una petición de Student para que se envíe
  * la simulación en reproducción actual a través del socket service
  * @param request objeto SocketMessage
  */
  override sendCurrentParameters(request: SocketMessage)
  {
    super.sendCurrentParameters(request);

    if(request.from?.device === Device.REPLICA && this.config.ecmoMode === EcmoMode.REPLICA && this.config.replicaEnabled)
    {
      //si hay una simulación en proceso enviamos los datos de parámetro solo de tipo parametro
      //en caso contrario enviamos un  array vacio
      this.socketService.send(request.action!,
                              this.socketService.addresses.replica,
                              (this.selectorService.simulationPlaying) ? this.parameters.filter(m => m.idParameterType === ParameterType.PARAMETER) : [],
                              request.id);
    }
  }

  sendAndRetriveControlSet(request:SocketMessage):Promise<any>
  {
    return this.socketService.sendAndRetrive(request.action!, this.socketService.getAddressByDevice(this.config.device), request.data, request.id);
  }
}
