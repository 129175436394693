import { Injectable } from "@angular/core";
import { EventManagerService } from "event-manager";
import { HVitaSimulationTeacherService } from "hvita-simulation";
import { HVitaConfigService } from "./hvita-config.service";
import { HVitaConfigTeacherInterface, InteractiveMode, PreasureType, RhythmType } from "hvita-common";
import { Device, EcmoMode } from "hybrids-config";
import { SocketAction, SocketMessage, SocketResult } from "hybrids-socket";
import { HVitaSocketService } from "./hvita-socket.service";
import { HVitaEvent } from "../enums/hvita-event.enum";
import { DialogBoxService } from "dialog-box";
import { HVitaScenarioService } from "./hvita-scenario.service";
import { BatteryStat, HVitaBatteryService } from "hvita-battery";
import { ModalBoxService } from "modal-box";
import { Router } from "@angular/router";
import { HVitaLogService } from "./hvita-log.service";
import { HVitaSimulationSelectorService } from "hvita-simulation-selector";
import { HVitaScenarioEvent } from "hvita-scenario";
import { HVitaParameterService } from "./hvita-parameter.service.";
import { HVitaTemplateService } from "./hvita-template.service";
import { HVitaIpcService } from "./hvita-ipc.service";
import { PatientMonitorService } from "./patient-monitor.service";
import { PatientMonitorEvent, SelectList } from "patient-monitor";

/**
 * Gestiona los aspectos generales de la simulacion
 */
@Injectable({
  providedIn: 'root'
})
export class HVitaSimulationService extends HVitaSimulationTeacherService
{
  constructor(eventManagerService: EventManagerService,
              configService: HVitaConfigService,
              dialogService: DialogBoxService,
              modalBoxService: ModalBoxService,
              socketService: HVitaSocketService,
              scenarioService: HVitaScenarioService,
              logService:HVitaLogService,
              selectorService:HVitaSimulationSelectorService,
              parameterService:HVitaParameterService,
              templateService:HVitaTemplateService,
              private monitorService:PatientMonitorService,
              private batteryService: HVitaBatteryService,
              private ipcService:HVitaIpcService,
              private router:Router)
  {
    super(eventManagerService,
          configService,
          dialogService,
          modalBoxService,
          socketService,
          scenarioService,
          logService,
          templateService,
          parameterService,
          selectorService);
  }

  override initialize()
  {
    super.initialize();

    this.eventManagerService.subscribeEvent<InteractiveMode>(HVitaEvent.CHANGE_INTERACTIVE_MODE, (mode:InteractiveMode) =>
    {
      //actualizamos el modo de ventilación en alumno si el modo interativo esta desactivado
      if(mode === InteractiveMode.DISABLED)
        this.socketService.send(SocketAction.SET_VENTILATION_MODE, this.socketService.addresses.student, this.conf.config.ventilationMode);
    });

    this.eventManagerService.subscribeEvent<boolean>(HVitaScenarioEvent.SCENARIO_LOADING, newScenario =>
    {
      if(this.monitorService.playing)
        this.monitorService.pause();

      this.monitorService.stop();

      if(newScenario)
      {
        //reseteamos los ritmos del monitor de paciente
        this.monitorService.resetRhythms();

        //reseteamos las vistas del monitor de paciente
        this.monitorService.resetView();
      }
    });

    this.eventManagerService.subscribeEvent<boolean>(HVitaScenarioEvent.SCENARIO_LOADED, () =>
    {
      this.eventManagerService.emitEvent(PatientMonitorEvent.SELECT_LIST_RHYTHM_VALUES,
                                         new SelectList({ selectedValue: RhythmType.SINUS, selectedOptions: new Array<string>() }));

      this.eventManagerService.emitEvent(HVitaScenarioEvent.UPDATE_CANVASES);

      if(this.monitorService.paused)
        this.monitorService.resume();

      setTimeout(() => this.monitorService.start(), 0);
    });
  }

  /**
   * Envía una petición de reseteo de válvulas a Control
   */
  sendResetPreasure() : Promise<void>
  {
    return new Promise<void>((resolve, reject) =>
    {
      this.socketService.sendAndRetrive(SocketAction.RESET_VALVES, this.socketService.getAddressByDevice(this.conf.device)).then((response:any) =>
      {
        if(response.result === SocketResult.READY)
        {
          this.parameterService.currentOcclusionP1 = 0;
          this.parameterService.currentOcclusionP3 = 0;
          resolve();
        }
        else
        {
          this.parameterService.showWarningPreasure(response.result, false);
          reject();
        }
      });
    });
  }

  /**
   * Comprueba y establece los límites máximos y mínimos de p1 y p3 en función del modo de ECMO
   */
  checkPreasureLimits()
  {
    //comprobamos el modo y establecemos máximos y mínimos de p1 y p3 en función del modo
    const p1 = this.parameterService.parameters.find(m => m.key === 'p1')!;
    const p3 = this.parameterService.parameters.find(m => m.key === 'p3')!;

    const pven = this.parameterService.parameters.find(m => m.key === 'pven')!;
    const part = this.parameterService.parameters.find(m => m.key === 'part')!;

    //cambiamos los limites de los parametros de p1 y p3
    // si estamos en modo real los cambiamos a su valor original, en los demás casos al valor de pven y part
    const values =
    {
      'p1' :
      {
        'min' : (this.conf.ecmoMode === EcmoMode.REAL) ? p1.values![0].minValue : pven.values![0].minValue,
        'max' : (this.conf.ecmoMode === EcmoMode.REAL) ? p1.values![0].maxValue : pven.values![0].maxValue
      },
      'p3' :
      {
        'min' : (this.conf.ecmoMode === EcmoMode.REAL) ? p3.values![0].minValue : part.values![0].minValue,
        'max' : (this.conf.ecmoMode === EcmoMode.REAL) ? p3.values![0].maxValue : part.values![0].maxValue
      },
    };

    this.eventManagerService.emitEvent(HVitaScenarioEvent.CHANGE_LIMIT_PREASURES, values);
  }

  /**
   * Obtiene los valores de los parámetros gestionados por Control
   */
  getBatteryStats()
  {
    if((this.conf.simulationDevice === Device.VITABOX && this.conf.vitaboxEnabled) || (this.conf.simulationDevice === Device.REPLICA && this.conf.replicaEnabled))
      this.socketService.sendAndRetrive(SocketAction.GET_BATTERY, this.socketService.getAddressByDevice(this.conf.device)).then((response:any) => this.changeBattery(response.data));
    else
      this.resetBatteryStats();
  }

  /**
   * Cambia los valores de la batería
   * @param data Lista de objetos SocketDataSet con los nuevos valores
   */
  changeBattery(data:any)
  {
    try
    {
      //los parametros pueden llegar o no en función de los cambios producidos en Control
      //hay que comprobar que datos han llegado antes de actualizar el valor
      const level = data.find((m:any) => m.key === 'level');
      const stat = data.find((m:any) => m.key === 'stat');

      if(level)
        this.batteryService.batteryLevel = Math.round(Number(level.value));

      if(stat)
        this.batteryService.batteryStat = Number(stat.value);
    }
    catch
    {
      this.resetBatteryStats();
    }
  }

  /**
   * Resetea los valores de la batería
   */
  resetBatteryStats()
  {
    this.batteryService.batteryStat = BatteryStat.UNPLUGGED;
    this.batteryService.batteryLevel = 0;
  }

  /**
   * Cambia el modo de simulación
   * @param mode modo de simulación
   */
  changeEcmo()
  {
    if(this.conf.vitaboxEnabled || this.conf.replicaEnabled)
    {
      let mode = (this.conf as HVitaConfigService).getEcmoMode();

      const device = (this.conf.device === Device.NONE) ?
                      ((this.conf.simulationDevice === Device.VITABOX && this.conf.vitaboxEnabled) ?
                        Device.VITABOX :
                        (this.conf.simulationDevice === Device.REPLICA && this.conf.replicaEnabled) ?
                          Device.REPLICA :
                          Device.NONE) :
                     this.conf.device;

      if(device === Device.VITABOX)
        mode = (mode === EcmoMode.REAL) ? EcmoMode.VIRTUAL : EcmoMode.REAL;
      else if(device === Device.REPLICA)
        mode = (mode === EcmoMode.REPLICA) ? EcmoMode.VIRTUAL : EcmoMode.REPLICA;

      (this.conf as HVitaConfigService).setEcmoMode(mode!);

      this.checkPreasureLimits();
      this.setPreasureType((this.conf.ecmoMode === EcmoMode.REPLICA) ? PreasureType.VALUE : this.stage!.idPreasureType!);
      this.stopStage();
      this.getControlValues();
      this.getBatteryStats();
    }
  }

  /**
  * Establece un nuevo tipo de presión para la aplicación
  * @param mode enum PreasureType
  */
  setPreasureType(mode: PreasureType)
  {
    this.stage!.idPreasureType = this.conf.config.preasureType = mode;

    //reseteamos los mensajes de presion no viable
    this.parameterService.invalidPreasure = '';
  }

  /**
   * Obtiene los valores de los parámetros gestionados por Control
   */
  getControlValues()
  {
    //solo obtenemos los valores de real
    if(this.conf.ecmoMode === EcmoMode.REAL)
      this.socketService.sendAndRetrive(SocketAction.GET_VALUES, this.socketService.getAddressByDevice(this.conf.device)).then((response:any) => this.changeControlValues(response.data));
  }

  /**
   * Actualiza los valores de los parámetros que hayan llegado desde Control
   * @param data parámetros recibidos
   */
  changeControlValues(data:any)
  {
    if(data)
    {
      for (const item of data)
      {
        let hasChange = false;

        const param = this.parameterService.composeParameterFromServer(item);

        if(param)
        {
          const parameter = this.parameterService.parameters.find(m => m.idParameter === param.idParameter)!;

          for (let i = 0; i < parameter.stageParameter!.parametersValues!.length; i++)
          {
            //comprobamos si hay algún cambio en los valores
            if(parameter.stageParameter!.parametersValues![i].value != param!.nextStageParameter!.parametersValues![i].value)
            {
              parameter.stageParameter!.parametersValues![i].value = param!.nextStageParameter!.parametersValues![i].value;
              hasChange = true;
            }
          }

          parameter.stageParameter!.timerDuration = 0;
          parameter.stageParameter!.timerInit = 0;

          this.parameterService.resetParameter(parameter);

          this.parameterService.checkBlink(parameter);

          if(parameter.key === 'pven' || parameter.key === 'part')
          {
            const preasure = this.parameterService.parameters.find(m => m.key === ((parameter.key === 'pven') ? 'p1' : 'p3'))!;

            if(!preasure.lock)
            {
              preasure.stageParameter!.parametersValues![0].value = parameter.stageParameter!.parametersValues![0].value;

              if(this.conf.config.preasureType === PreasureType.VALUE)
                this.eventManagerService.emitEvent(HVitaScenarioEvent.UPDATE_VALUE, preasure.idParameter);
            }
          }

          //enviamos el cambio a Student
          if(hasChange && this.conf.studentEnabled)
            this.socketService.send(SocketAction.PARAMETER, this.socketService.addresses.student, parameter);
        }
      }
    }
  }

  /**
   * Envía orden de resetear las válvulas de la VitaBox
   */
  resetValves():Promise<void>
  {
    return new Promise<void>((resolve, reject) =>
    {
      //si estamos en modo real ordenamos un reseteo de las válvulas
      //obtenemos los valores rales de la controladora
      //y después ejecutamos el estadio actual

      //Incluye modo REAL y REPLICA
      //if(this.confService.ecmoMode !== EcmoMode.VIRTUAL && this.socketService.enabledConnection)

      //solo modo REAL
      if(this.conf.ecmoMode === EcmoMode.REAL && this.socketService.enabledConnection)
      {
        //enviamos el modo de simulación
        //Incluye modo REAL y REPLICA
        // if((this.confService.ecmoMode === EcmoMode.REAL && this.confService.vitaboxEnabled) ||
        //    (this.confService.ecmoMode === EcmoMode.REPLICA && this.confService.replicaEnabled && !this.confService.isVR))

        //solo REAL
        if(this.conf.vitaboxEnabled)
          this.socketService.send(SocketAction.ECMO_MODE, this.socketService.getAddressByDevice(this.conf.device), this.conf.ecmoMode);

        this.sendResetPreasure().then(() =>
        {
          //Incluye modo REAL y REPLICA
          //((this.confService.device === Device.REPLICA && this.confService.isVR) ? this.hybridsBridgeService : this.socketService).sendAndRetrive(SocketAction.GET_VALUES, this.confService.device).then((response:any) =>

          //solo REAL
          this.socketService.sendAndRetrive(SocketAction.GET_VALUES, this.socketService.getAddressByDevice(this.conf.device)).then((response:any) =>
          {
            if(response.data)
            {
              for (const data of response.data)
              {
                const param = this.parameterService.composeParameterFromServer(data);

                if(param)
                {
                  let stageParam = this.stage!.parameters!.find(m => m.idParameter === param.idParameter);

                  if(!stageParam)
                  {
                    stageParam = this.scenarioService.basal!.stages![0].parameters!.find((m:any) => m.idParameter === param.idParameter)?.cloneObject();
                    this.stage!.parameters!.push(stageParam!);
                  }

                  const index = (param.key === 'hbhc') ? ((data.key === 'hct') ? 0 : 1) : 0;

                  stageParam!.parametersValues![index].value = param.nextStageParameter!.parametersValues![index].value;

                  stageParam!.timerDuration = 0;
                  stageParam!.timerInit = 0;

                  //actualizamos los valores de p1 y p3 con los valores obtenidos para pven y part
                  if(param.key === 'pven' || param.key === 'part')
                  {
                    const preasure = this.parameterService.parameters!.find(m => m.key === ((param.key === 'pven') ? 'p1' : 'p3'))!;
                    preasure.stageParameter!.parametersValues![0].value = param.stageParameter?.parametersValues![0].value;
                  }
                }
              }
            }

            resolve();
          });
        }).catch(err =>
        {
          reject();
        });
      }
      else
      {
        //si estamos en modo replica enviamos el ecmo mode
        if(this.conf.ecmoMode === EcmoMode.REPLICA && this.conf.replicaEnabled)
          this.socketService.sendAndRetrive(SocketAction.ECMO_MODE, this.socketService.getAddressByDevice(this.conf.device), this.conf.ecmoMode);

        resolve();
      }
    });
  }

  /**
   * Gestiona un posible fallo a la hora de resetear las válvulas de la VitaBox
   */
  resetValvesFailed()
  {
    this.resetScenario();
    this.modalBoxService.hide();
    this.router.navigate(['/', 'home']);
  }

  /**
   * Inicia la reproducción de un nuevo estadio
   */
  sendNotifications()
  {
    //enviamos el stage a Student
    if(this.conf.studentEnabled)
    {
      this.socketService.send(SocketAction.WEB_SERVER_TEACHER, this.socketService.addresses.student, this.ipcService.storageURL);
      this.socketService.send(SocketAction.MUTE_STATUS, this.socketService.addresses.student, this.conf.muted);
      this.socketService.send(SocketAction.STAGE, this.socketService.addresses.student, this.parameterService.parameters);
    }

    //si replica está conectada enviamos el stage
    if(this.conf.ecmoMode === EcmoMode.REPLICA && this.conf.replicaEnabled)
        this.socketService.send(SocketAction.STAGE, this.socketService.addresses.replica, this.parameterService.parameters);
  }

  /**
   * Limpia un escenario al cerrar la pantalla de simulación
   */
  override clearScenario()
  {
    super.clearScenario();

    this.monitorService.disableSound();
    this.monitorService.resetRhythms();
    this.monitorService.resetView();
    this.monitorService.stop();

    //cambiamos el modo ecmo a Control para que deje de enviar datos
    if(this.conf.vitaboxEnabled)
      this.socketService.send(SocketAction.ECMO_MODE, this.socketService.addresses.vitabox, EcmoMode.VIRTUAL);

    //cambiamos el modo ecmo a Replica para que deje de enviar datos
    if(this.conf.replicaEnabled)
      this.socketService.send(SocketAction.ECMO_MODE, this.socketService.addresses.replica, EcmoMode.VIRTUAL);
  }

  sendTestList(request: SocketMessage)
  {
    this.socketService.send(request.action!, request.from!, this.scenario!.tests, request.id);
  }
}
