export interface ServerPlugin 
{
  /**
   * Activa el servidor web
   * @returns sttring
   */
  enable(): Promise<string>;

  /**
   * Desativa el servidor web
   * @returns string
   */
  disable(): Promise<string>;

  // /**
  //  * Devuelve la url de la carpeta de almacenamiento de imágenes y vídeos
  //  */
  // url(): Promise<string>;
}