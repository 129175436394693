import { Injectable } from '@angular/core';
import { AgeMode, HVitaConfig, HVitaConfigTeacherService, InteractiveMode } from 'hvita-common';
import { Device, EcmoMode } from 'hybrids-config';
import { SocketAction, SocketMessage } from 'hybrids-socket';
import { CalibrationType } from '../enums/calibration-type.enum';
import { HVitaSocketService } from './hvita-socket.service';
import { HVitaIpcService } from './hvita-ipc.service';
import { EventManagerService } from 'event-manager';
import { HVitaEvent } from '../enums/hvita-event.enum';
import { HVitaSimulationSelectorService } from 'hvita-simulation-selector';


/**
 * Gestiona la configuración global de la aplicación
 */
@Injectable({
  providedIn: 'root'
})
export class HVitaConfigService extends HVitaConfigTeacherService
{
  localStorageCalibration = 'hvita.teacher.calibration';

  calibration: CalibrationType | undefined;

  constructor(config: HVitaConfig,
              private selectorService:HVitaSimulationSelectorService,
              private socketService: HVitaSocketService,
              private ipcService: HVitaIpcService,
              private eventManager: EventManagerService
  )
  {
    super(config);

    this.eventManager.addEvent<InteractiveMode>(HVitaEvent.CHANGE_INTERACTIVE_MODE);
  }

  /**
   * Inicializa el servicio
   */
  initialize()
  {
    //obtenemos el último modo de simulación seleccionado por el usuario
    //si no existe se establece como Real por defecto
    //esta configuración puede cambiar posteriormente en función
    //de si Control está conectado o no
    this.ecmoMode = this.getEcmoMode();

    // if(!this.ecmoMode)
    //   this.setEcmoMode(EcmoMode.VIRTUAL);

    //Obtenemos el entorno elegido por el usuario
    this.ageMode = this.getAgeMode();

    //obtenemos el último modo de interacción seleccionado por el usuario
    //si no existe se establece Disabled por defecto
    this.interactiveMode = this.getInteractiveMode();

    if(!this.interactiveMode)
    {
      this.interactiveMode = InteractiveMode.DISABLED;
      this.setInteractiveMode(this.interactiveMode);
    }

    //obtenemos la última calibración seleccionada por el usuario
    this.calibration = this.getCalibration();

    // limpiamos modo y tipo de canulacion
    this.removeCannulationType();
    this.removeCannulationMode();
  }

  /**
   * Obtiene el último modo de simulación que se haya utilizado en la aplicación
   * @returns enum EcmoMode
   */
  getEcmoMode()
  {
    const mode = localStorage.getItem(this.localStorageEcmoMode);
    return (mode) ? Number(mode) : null;
  }


  /**
  * Establece un nuevo modo de simulación para la aplicación
  * @param mode enum EcmoMode
  */
  setEcmoMode(mode: EcmoMode, send:boolean = true)
  {
    this.ecmoMode = mode;

    switch(this.ecmoMode)
    {
      case EcmoMode.VIRTUAL: /*this.device = Device.NONE;*/ break;
      case EcmoMode.REAL: this.device = Device.VITABOX; break;
      case EcmoMode.REPLICA: this.device = Device.REPLICA; break;
    }

    localStorage.setItem(this.localStorageEcmoMode, mode.toString());

    if(send)
    {
      //enviamos el cambio de modo
      if(this.studentEnabled)
        this.socketService.send(SocketAction.ECMO_MODE, this.socketService.addresses.student, mode);

      if(this.vitaboxEnabled)
        this.socketService.send(SocketAction.ECMO_MODE, this.socketService.addresses.vitabox, mode);

      if(this.replicaEnabled)
        this.socketService.send(SocketAction.ECMO_MODE, this.socketService.addresses.replica, mode);
    }
  }


  /**
   * Envía el modo de ecmo seleccionado
   * @param request SocketMessage
   */
  sendEcmoMode(request:SocketMessage)
  {
    //Replica y Vitabox solo necesitan saber el modo de ecmo si se está reproduciendo un escenario
    //en caso contrario siempre deben recibir modo Virtual para evitar el envío de datos innecesarios
    const mode = ((request.from.device === Device.VITABOX || request.from.device === Device.REPLICA) && !this.selectorService.simulationPlaying) ?  EcmoMode.VIRTUAL : this.ecmoMode;

    this.socketService.send(request.action!, request.from!, mode, request.id);
  }


  /**
  * Obtiene el último modo de interacción con el alumno que se haya utilizado en la aplicación
  * @returns enum EcmoMode
  */
  getInteractiveMode()
  {
    const mode = localStorage.getItem(this.localStorageInteractiveMode);
    return (mode) ? Number(mode) : null;
  }

  /**
  * Establece un nuevo modo de interacción con el alumno para la aplicación
  * @param mode enum InteractiveMode
  */
  setInteractiveMode(mode: InteractiveMode)
  {
    this.interactiveMode = mode;

    localStorage.setItem(this.localStorageInteractiveMode, mode.toString());

    //enviamos el cambio de modo
    if(this.studentEnabled)
    {
      this.socketService.send(SocketAction.INTERACTIVE_MODE, this.socketService.addresses.student, mode);
      this.eventManager.emitEvent(HVitaEvent.CHANGE_INTERACTIVE_MODE, mode);
    }
  }

  /**
   * Envía el modo de interacción
   * @param request SocketMessage
   */
  sendInteractiveMode(request:SocketMessage)
  {
    this.socketService.send(request.action!, request.from!, this.interactiveMode, request.id);
  }

  /**
   * Obtiene la última calibración que se haya utilizado en la aplicación
   * @returns enum CalibrationType
   */
  getCalibration()
  {
    const calibration = localStorage.getItem(this.localStorageCalibration);
    return (calibration) ? Number(calibration) : CalibrationType.LEGS_1;
  }

  /**
  * Almacena la calibración seleccionada para la aplicación
  * @param mode enum CalibrationType
  */
  setCalibration(calibration: CalibrationType)
  {
    this.calibration = calibration;
    localStorage.setItem(this.localStorageCalibration, calibration.toString());
  }

  /**
   * Envía la url del servidor web instalado en Teacher
   * @param request SocketMessage
   */
  sendWebServerTeacherUrl(request:SocketMessage)
  {
    this.socketService.send(request.action!, request.from!, this.ipcService.storageURL, request.id);
  }
}
