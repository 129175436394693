import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { HVitaEditionInfoBoxComponent } from './hvita-edition-info-box.component';


@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule
  ],
  exports: [HVitaEditionInfoBoxComponent],
  declarations: [HVitaEditionInfoBoxComponent]
})
export class HVitaEditionInfoBoxComponentModule {}
