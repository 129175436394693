import { Injectable } from "@angular/core";
import { MonitorTeacherService, PatientMonitorConfig, SelectListItem } from "patient-monitor";
import { HVitaSocketService } from "./hvita-socket.service";
import { HVitaConfigService } from "./hvita-config.service";
import { EventManagerService } from "event-manager";
import { Query, RepositoryService, Table } from "sqlite";
import { Rhythm, RhythmOption } from "hvita-common";

/**
 * Gestiona las pruebas complementarias que se muestran en una simulación
 */
@Injectable({
  providedIn: 'root'
})
export class PatientMonitorService extends MonitorTeacherService
{
  constructor(configService: HVitaConfigService,
              socketService: HVitaSocketService,
              eventManagerService: EventManagerService,
              config: PatientMonitorConfig,
              private repositoryService:RepositoryService)
  {
    super(configService, socketService, eventManagerService, config);
  }

  /**
  * Precarga los elementos necesarios para la gestión del monitor de paciente
  * @returns Promise<void>
  */
  override initialize(order:string[]): Promise<void>
  {
    return new Promise<void>((resolve, reject) =>
    {
      try
      {
        super.initialize(order).then(() =>
        {
          // cargamos la lista de ondas
          this.repositoryService.exeQuery(new Query([new Table('Waves')])).then(waves =>
          {
            this.waves = waves;

            // cargamos las variables de derivación
            this.repositoryService.exeQuery(new Query([new Table('DerivationsVariables')])).then(derivationVariables =>
            {
              this.derivationVariables = derivationVariables;

              // cargamos la lista de damping
              this.repositoryService.exeQuery(new Query([new Table('Dampings')])).then(dampings =>
              {
                this.dampings = dampings;

                //creamos la lista de ritmos
                this.repositoryService.exeQuery(new Query([new Table('Rhythms')])).then((rhythms: Rhythm[]) =>
                {
                  this.rhythms = new Array<SelectListItem>();

                  rhythms.map(rhythm =>
                  {
                    this.rhythms.push(new SelectListItem(
                    {
                      label: rhythm.name,
                      value: rhythm.idRhythm
                    }));
                  });

                  this.repositoryService.exeQuery(new Query([new Table('RhythmsOptions')])).then((options:RhythmOption[]) =>
                  {
                    this.rhythmsOptions = new Array<SelectListItem>();

                    let idGroup:number | null = null;

                    options.map(rhythmOption =>
                    {
                      if(idGroup !== rhythmOption.idGroup)
                      {
                        //añadimos una opcion vacía que servirá de separador
                        this.rhythmsOptions.push(new SelectListItem({}));
                        idGroup = rhythmOption.idGroup!;
                      }

                      this.rhythmsOptions.push(new SelectListItem(
                      {
                        label: rhythmOption.name,
                        value: rhythmOption.idRhythmOption,
                        group: rhythmOption.idGroup
                      }));
                    });

                    resolve();
                  });
                });
              });
            });
          });
        })
        .catch(() => reject());
      }
      catch (err)
      {
        reject();
      }
    });
  }
}
