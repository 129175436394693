<hybrids-menu logo="./hvita/assets/images/SVG/logo_black.svg" button="./hvita/assets/images/SVG/burger_disabled.svg">
  <nav main>
    <a (click)="navigateTo('home')">{{ 'home' | translate }}</a>
    <a (click)="navigateTo('configuration')">{{ 'configuracion' | translate }}</a>
    <a *ngIf="electronService.isElectronApp && (ipcService.info?.mode === ModeVersion.DEVELOP || (ipcService.drive && ipcService.drive.isMasterKey))" (click)="navigateTo('wireless')">{{ 'wireless' | translate }}</a>
    <!-- <a (click)="navigateTo('help')">{{ 'ayuda' | translate }}</a> -->
    <a (click)="openSoftwareVersion()">{{ 'info' | translate }}</a>
    <a (click)="closeApp()">{{ 'salir' | translate }}</a>
  </nav>
</hybrids-menu>
