import { EventManagerService } from 'event-manager';
import { Injectable } from "@angular/core";
import { Scenario, SectionType, Test, StageParameter, StageParameterValue, TestType, PreasureType, Stage } from "hvita-common";
import { ModalBoxService } from 'modal-box';
import { HVitaScenarioService } from './hvita-scenario.service';
import { HVitaEditionBaseService, HVitaEditionDistribution, HVitaEditionParameter } from 'hvita-edition';
import { ManageFile } from 'manage-file';
import { Condition, ConditionOperator, Field, Query, RepositoryService, Table } from 'sqlite';
import { DialogBoxData, DialogBoxType, DialogBoxService } from 'dialog-box';
import { Info } from 'info';
import { Usb } from 'usb';
import { Common } from 'common';

/**
 * Gestiona toda la edición de escenarios y estadios
 */
@Injectable({
  providedIn: 'root'
})
export class HVitaEditionService extends HVitaEditionBaseService
{
  constructor(eventManagerService: EventManagerService,
              modalBoxService:ModalBoxService,
              scenarioService:HVitaScenarioService,
              private repositoryService:RepositoryService,
              private dialogService:DialogBoxService)
  {
    super(eventManagerService, modalBoxService, scenarioService);

    this.manageDistribution =
    [
      [ new HVitaEditionDistribution({ title: 'ecmo', types: [SectionType.ECMO_PREASURE, SectionType.ECMO_FLOW] }) ],
      [ new HVitaEditionDistribution({ title: 'vent_artificial', types: [SectionType.VENTILATION] }) ],
      [ new HVitaEditionDistribution({ title: 'monitor', types: [SectionType.MONITOR]  }), new HVitaEditionDistribution({ title: 'datos_cerebrales', types: [SectionType.NEURO]  }) ],
      [ new HVitaEditionDistribution({ title: 'programador', types: [SectionType.CONTROLLER]  }) ],
    ];
  }

  /**
   * Devuelve la ruta del test
   * @param test test
   * @returns ruta archivo
   */
  async getPathTest(test: Test):Promise<string>
  {
    return new Promise<string>((resolve) =>ManageFile.getStoragePath().then(file => resolve(file + '\\' + ((test.idTestType === 1) ? 'images' : 'videos') + '\\' + test.file)));
  }

  /**
   * Devuelve la uri del test
   * @param test test
   * @returns uri del archivo
   */
  async getUriTest(test: Test):Promise<string>
  {
    return new Promise<string>((resolve) => (test.filePath) ? resolve(test.filePath) : this.getPathTest(test).then((result) => resolve(result)));
  }

  /**
   * Elimina un escenario a partir de su id
   * @param idScenario id del escenario
   * @returns Promise<void>
   */
  async deleteScenario(idScenario:number) : Promise<void>
  {
    //hay que hacer un borrado lógico y en cascada de todos los datos relacionados
    let table = new Table('Stages', [new Field('idScenario', [new Condition(ConditionOperator.EQUAL, idScenario)])]);
    const stages = await this.repositoryService.exeQuery(new Query([table]));

    for (const stage of stages)
    {
      table = new Table('StagesParameters', [new Field('idStage', [new Condition(ConditionOperator.EQUAL, stage.idStage)])]);
      stage.parameters = await this.repositoryService.exeQuery(new Query([table]));

      for (const parameter of stage.parameters)
        await this.repositoryService.delete('StagesParametersValues', 'idStageParameter', parameter.idStageParameter);

      await this.repositoryService.delete('StagesParameters', 'idStage', stage.idStage);
    }

    await this.repositoryService.delete('Stages', 'idScenario', idScenario);


    //eliminamos los ficheros fisicos
    table = new Table('Tests', [new Field('idScenario', [new Condition(ConditionOperator.EQUAL, idScenario)])]);

    const tests = await this.repositoryService.exeQuery(new Query([table]));

    for (const test of tests)
    {
      const path = await this.getPathTest(test);

      if(path)
        await ManageFile.delete(path);
    }

    await this.repositoryService.delete('Tests', 'idScenario', idScenario);

    const result = await this.repositoryService.delete('Scenarios', 'idScenario', idScenario);

    return result;
  }

  /**
   * Inserta un escenario en la base de datos
   * @param idScenario id del escenario
   * @param position posición del estadio
   * @param preasuretype tipo de presión
   */
  insertStage(idScenario:number, position:number, preasuretype:PreasureType):Promise<number>
  {
    return this.repositoryService.insert('Stages', [ 'idScenario', 'position', 'idPreasureType' ], [ idScenario, position, preasuretype ], 'idStage');
  }

  /**
   * Elimina un estadio a partir de su id
   * @param idStage id del estadio
   * @returns Promise<void>
   */
  async deleteStage(idStage:number) : Promise<void>
  {
    //hay que hacer un borrado lógico y en cascada de todos los datos relacionados
    const table = new Table('StagesParameters', [new Field('idStage', [new Condition(ConditionOperator.EQUAL, idStage)])]);
    const parameters = await  this.repositoryService.exeQuery(new Query([table]));

    for (const parameter of parameters)
      await this.repositoryService.delete('StagesParametersValues', 'idStageParameter', parameter.idStageParameter);

    await this.repositoryService.delete('StagesParameters', 'idStage', idStage);

    const result = await this.repositoryService.delete('Stages', 'idStage', idStage);

    return result;
  }

  /**
   * Inserta o edita un escenario
   * @param data datos editados
   */
  saveScenario(data:any) : Promise<Scenario>
  {
    return new Promise(async (resolve, reject) =>
    {
      let fields: string[] = [];
      let values: any[] = [];

      fields.push('idAgeMode');
      values.push(data.scenario.idAgeMode);

      //La ventana de edición de escenarios puede mostrar datos parciales o todos los datos
      //Vamos añadiendo campos a la consulta en función de la vista
      if(data.view === 'all' || data.view === 'name')
      {
        fields.push('name');
        values.push(data.scenario.name);
      }

      if(data.view === 'all' || data.view === 'labels')
      {
        fields.push('idCannulationMode');
        values.push(data.scenario.idCannulationMode);

        fields.push('idCannulationType');
        values.push(data.scenario.idCannulationType);
      }

      if(data.scenario.idScenario)
      {
        //estamos actualizando datos
        await this.repositoryService.update('Scenarios', fields, values, 'idScenario', data.scenario.idScenario);
      }
      else
      {
        //estamos insertando un nuevo escenario
        fields.push('isDefault');
        values.push(false);

        fields.push('isBasal');
        values.push(false);

        data.scenario.idScenario =await this.repositoryService.insert('Scenarios', fields, values, 'idScenario');

        for (const stage of data.scenario.stages)
        {
          //guardamos los estadíos
          fields = [ 'idScenario', 'position', 'idPreasureType' ];
          values = [ data.scenario.idScenario, stage.position, stage.idPreasureType ];

          //añadimos estadios si estamos duplicando o creando uno nuevo
          if(!stage.idStage)
          {
            stage.idStage = await this.repositoryService.insert('Stages', fields, values, 'idStage');

            //añadimos nuevos parametros si estamos duplicando
            if(stage.parameters)
            {
              for (const parameter of stage.parameters)
              {
                parameter.idStage = stage.idStage;

                fields = [ 'idParameter', 'idStage', 'idSetterType', 'idRelativeType', 'timerInit', 'timerDuration' ];
                values = [ parameter.idParameter, stage.idStage, parameter.idSetterType, parameter.idRelativeType, parameter.timerInit, parameter.timerDuration ];

                parameter.idStageParameter = await this.repositoryService.insert('StagesParameters', fields, values, 'idStageParameter');

                for (const param of parameter.parametersValues)
                {
                  param.idStage = stage.idStage;

                  fields = [ 'idParameterValue', 'idStageParameter', 'value', 'occlusion', 'idBubbleType', 'idColor' ];
                  values = [ param.idParameterValue, parameter.idStageParameter, param.value, param.occlusion, param.idBubbleType, param.idColor ];

                  param.idValue = await this.repositoryService.insert('StagesParametersValues', fields, values, 'idValue');
                }
              }
            }
          }
        }
      }

      if(data.scenario.conditions?.length > 0)
      {
        for(const condition of data.scenario.conditions)
        {
          fields = [ 'idScenario', 'keyCompare', 'idOperatorCondition', 'valueCompare', 'keyResult', 'valueResult' ];
          values = [ data.scenario.idScenario, condition.keyCompare, condition.idOperatorCondition, condition.valueCompare, condition.keyResult, condition.valueResult ];

          if(!condition.idCondition)
            condition.idCondiciton = await this.repositoryService.insert('Conditions', fields, values, 'idCondition');
        }
      }

      //gestionamos los tests
      if(data.view === 'tests' || data.view === 'all')
      {
        for (const test of data.scenario.tests)
        {
          fields = [
            'idScenario',
            'name',
            'size',
            'mime',
            'idTestType',
            'file',
            'fileName'
          ];

          values = [
            data.scenario.idScenario,
            test.name,
            test.size,
            test.mime,
            test.idTestType,
            test.file,
            test.fileName
          ];

          const path = await this.getPathTest(test);

          if(test.idTest > 0)
          {
            //si el registro ya existe
            if(test.name)
            {
              //los registros con name deben ser actualizados
              if(test.filePath || test.fileInput)
              {
                //si hay filePath es que se ha subido un nuevo fichero
                //guardamos el fichero
                await this.saveTest(test, path, fields, values, 'update');
              }
              else
              {
                //en caso contrario sólo actualizamos los datos
                await this.repositoryService.update('Tests', fields, values, 'idTest', test.idTest);
              }
            }
            else
            {
              //los registros sin name deben ser eliminados
              await this.repositoryService.delete('Tests', 'idTest', test.idTest);

              if(path)
                await ManageFile.delete(path);
            }
          }
          else if(test.name)
          {
            if(test.filePath || test.fileInput)
            {
              //si hay filePath es que se ha subido un nuevo fichero
              //guardamos el fichero
              await this.saveTest(test, path, fields, values, 'insert');
            }
            else
            {
              //en caso contrario sólo insertamos los datos
              await this.repositoryService.insert('Tests', fields, values, undefined);
            }
          }
        }
      }

      //actualizamos la lista de escenarios
      this.scenarioService.scenarios = await this.scenarioService.getScenarios();

      resolve(this.scenarioService.scenarios[0].cloneObject());
    });
  }

  /**
   * Inserta o edita un estadio
   * @param data datos editados
   */
  saveStage(data:any) : Promise<Stage>
  {
    return new Promise(async (resolve, reject) =>
    {
      let fields: string[] = [];
      let values: any[] = [];

      fields.push('name');
      values.push(data.stage.name);

      //estamos actualizando datos
      await this.repositoryService.update('Stages', fields, values, 'idStage', data.stage.idStage);

      resolve(data.stage);
    });
  }

  /**
   * Devuelve si el nombre de un escenario está duplicado
   * @param name nombre del escenario
   * @param idScenario identificador necesario cuando estamos editando un escenario ya creado
   * @returns boolean
   */
  async isDuplicatedScenarioName(name: string, idScenario:number | undefined = undefined)
  {
    let duplicated = false;

    const res = await this.getOtherScenarios(idScenario);

    for (const scenario of res)
      if(scenario.name?.compareString(name))
        duplicated = true;

    return duplicated;
  }


  async getOtherScenarios(idScenario?:number | undefined):Promise<Scenario[]>
  {
    const query = ((idScenario)) ? new Table('Scenarios', [ new Field('idScenario', [new Condition(ConditionOperator.DISTINCT, idScenario)]) ])
                                 : new Table('Scenarios');

    return await this.repositoryService.exeQuery(new Query([query]));
  }

  /**
  * Guarda los cambios realizados en un parámetro
  * @returns Promise<StageParameter>
  */
  async saveParameter(data:HVitaEditionParameter) : Promise<StageParameter>
  {
    const stageParameter = new StageParameter(
    {
      idParameter: data.idParameter,
      idStage: data.idStage,
      idSetterType: data.idSetterType,
      idRelativeType: data.idRelativeType,
      timerInit: data.timerInit,
      timerDuration: data.timerDuration,

      parametersValues: new Array<StageParameterValue>()
    });

    let fields = [
      'idParameter',
      'idStage',
      'idSetterType',
      'idRelativeType',
      'timerInit',
      'timerDuration'
    ];

    let values = [
      stageParameter.idParameter,
      stageParameter.idStage,
      stageParameter.idSetterType,
      stageParameter.idRelativeType,
      stageParameter.timerInit,
      stageParameter.timerDuration
    ];

    if(data.idStageParameter)
    {
      await this.repositoryService.update('StagesParameters', fields, values, 'idStageParameter', data.idStageParameter);
      stageParameter.idStageParameter = data.idStageParameter;
    }
    else
    {
      stageParameter.idStageParameter = await this.repositoryService.insert('StagesParameters', fields, values, 'idStageParameter');
    }

    for (const value of data.stageParameterValues!)
    {
      fields = [
        'idStageParameter',
        'idParameterValue',
        'value',
        'occlusion',
        'idBubbleType',
        'idColor'
      ];

      values = [
        stageParameter.idStageParameter,
        value.idParameterValue,
        value.value,
        value.occlusion,
        value.idBubbleType,
        value.idColor
      ];

      let idValue = value.idValue!;

      if(idValue)
      {
        await this.repositoryService.update('StagesParametersValues', fields, values, 'idValue', idValue);
      }
      else
      {
        idValue = await this.repositoryService.insert('StagesParametersValues', fields, values, 'idValue');
      }

      stageParameter.parametersValues?.push(await this.repositoryService.getById('StagesParametersValues', 'idValue', idValue));
    }

    return stageParameter;
  }

  /**
   * Elimina un parámetro de un estadio
   *
   * @param idStage Identificador de estadio
   * @param idParameter Identificador de parámetro
   * @returns Identificador del parámetro eliminado
   */
  deleteParameter(idStage:number, idParameter:number) : Promise<number>
  {
    return new Promise<number>((resolve) =>
    {
      const table = new Table('StagesParameters',
      [
        new Field('idStage', [ new Condition(ConditionOperator.EQUAL, idStage) ]),
        new Field('idParameter', [ new Condition(ConditionOperator.EQUAL, idParameter) ])
      ]);

      this.repositoryService.exeQuery(new Query([table])).then(async data =>
      {
        const stageParameter = data[0];

        if(stageParameter)
        {
          await this.repositoryService.delete('StagesParametersValues', 'idStageParameter', stageParameter.idStageParameter);
          await this.repositoryService.delete('StagesParameters', 'idStageParameter', stageParameter.idStageParameter);

          resolve(stageParameter.idStageParameter);
        }
        else
        {
          resolve(0);
        }
      })
      .catch(() => resolve(0));
    });
  }

  /**
   * Actualiza el tipo de presión seleccionada para un estadio
   * @param idStage Identificador de estadio
   * @param idPreasureType Identificador de tipo de presión
   */
  async savePreasure(idStage:number, idPreasureType:number) : Promise<void>
  {
    await this.repositoryService.update('Stages', [ 'idPreasureType' ], [ idPreasureType ], 'idStage', idStage);
  }

  /**
   * Actualiza el tipo de presión seleccionada para un estadio
   * @param idStage Identificador de estadio
   * @param idPreasureType Identificador de tipo de presión
   */
  async saveTest(test:Test, path:string, fields:string[], values:any[], type:string) : Promise<void>
  {
    return new Promise<void>((resolve, reject) =>
    {
      try
      {

        if(path)
          ManageFile.move(test.filePath!, path)
                    .then(async () =>
                    {
                      if(type === 'insert')
                        await this.repositoryService.insert('Tests', fields, values, undefined);
                      else
                        await this.repositoryService.update('Tests', fields, values, 'idTest', test.idTest);
                    })
                    .catch(() => this.dialogService.show(new DialogBoxData({
                      type: DialogBoxType.ALERT,
                      message: 'guardar_media_error',
                      warning: true
                    })))
                    .finally(() => resolve());

      }
      catch(err)
      {
        reject(err);
      }
    });
  }


  /**
   * Abre el selector de ficheros file input
   */
  openFileInput(test:Test, allowedImageTypes:string[], allowedVideoTypes:string[]):Promise<void>
  {
    return new Promise<void>(async (resolve, reject) =>
    {
      const errors:any = {};

      errors['file'] = false;
      errors['file_format'] = false;

      if(await Info.isProduction() && !await Usb.hasDevice())
      {
        this.dialogService.show(new DialogBoxData({
          type: DialogBoxType.ALERT,
          message: 'inserte_usb',
          warning: true
        }));

        reject(errors);
      }
      else
      {
        ManageFile.select(await Usb.unit()).then(file =>
        {
          if(file != null)
          {
            const indexImage = allowedImageTypes.indexOf(file.mime);
            const indexVideo = allowedVideoTypes.indexOf(file.mime);

            if(indexImage >= 0 || indexVideo >= 0)
            {
              const ext = (indexImage >= 0) ? allowedImageTypes[indexImage].replace('image/', '') : allowedVideoTypes[indexVideo].replace('video/', '');

              //Construimos el objeto Test con los datos obtenidos
              test.file = Common.newGuid() + '.' + ext;
              test.size = file.size;
              test.mime = file.mime;
              test.idTestType = (indexImage >= 0) ? TestType.IMAGE : TestType.VIDEO;
              test.fileName = file.fileName;
              test.filePath = file.filePath;

              resolve();
            }
            else
            {
              //mostramos error
              errors['file'] = false;
              errors['file_format'] = true;

              reject(errors);
            }
          }
        }).catch(() => reject(errors));
      }
    });
  }
}
