import { Injectable } from "@angular/core";
import { WaveTeacherService } from "patient-monitor";
import { EventManagerService } from "event-manager";
import { PatientMonitorService } from "./patient-monitor.service";
import { HVitaConfigService } from "./hvita-config.service";
import { HVitaSocketService } from "./hvita-socket.service";

/**
 * Gestiona las pruebas complementarias que se muestran en una simulación
 */
@Injectable({
  providedIn: 'root'
})
export class PatientMonitorWaveService extends WaveTeacherService
{
  constructor(configService:HVitaConfigService,
              socketService:HVitaSocketService,
              monitorService: PatientMonitorService,
              eventManagerService: EventManagerService)
  {
    super(configService, socketService, monitorService, eventManagerService);
  }
}
