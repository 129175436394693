import { Injectable } from "@angular/core";
import { EventManagerService } from "event-manager";
import { HVitaConfigTeacherInterface, Test, TestType } from "hvita-common";
import { SocketAction } from "hybrids-socket";
import { HVitaConfigService } from "./hvita-config.service";
import { HVitaTemplateService } from "./hvita-template.service";
import { HVitaTestsBaseService, HVitaTestsEvent } from "hvita-tests";
import { HVitaSocketService } from "./hvita-socket.service";
import { HVitaIpcService } from "./hvita-ipc.service";
import { HVitaSimulationService } from "./hvita-simulation.service";
import { HVitaScenarioEvent } from "hvita-scenario";

/**
 * Gestiona las pruebas complementarias que se muestran en una simulación
 */
@Injectable({
  providedIn: 'root'
})
export class HVitaTestsService extends HVitaTestsBaseService
{
  constructor(configService: HVitaConfigService,
              eventManagerService: EventManagerService,
              private socketService:HVitaSocketService,
              private ipcService:HVitaIpcService)
  {
    super(configService, eventManagerService);
  }

  override initialize()
  {
    super.initialize();

    this.eventManagerService.subscribeEvent<void>(HVitaTestsEvent.IMG_MOVED, (rect) =>
    {

      //notificamos siempre independientemente del modo de interacción
      if((this.configService as HVitaConfigTeacherInterface).studentEnabled)
        this.socketService.send(SocketAction.IMG_MOVE, this.socketService.addresses.student, rect);
    });

    this.eventManagerService.subscribeEvent<void>(HVitaTestsEvent.IMG_ZOOMED, (data) =>
    {
      //notificamos siempre independientemente del modo de interacción
      if((this.configService as HVitaConfigTeacherInterface).studentEnabled)
        this.socketService.send((data.type === 'in') ? SocketAction.IMG_ZOOM_IN : SocketAction.IMG_ZOOM_OUT,
                                this.socketService.addresses.student,
                                data.rect);
    });
  }

  /**
   * Abre el visor de imágenes de la prueba complementaria escogida
   * @param idTest Identificador de la prueba complementaria
   */
  override async openViewer(test:Test)
  {
    super.openViewer(test);

    //siempre notificamos al alumno independientemente del modo de interacción
    if((this.configService as HVitaConfigTeacherInterface).studentEnabled)
      this.socketService.send((this.testSelected?.idTestType === TestType.IMAGE) ? SocketAction.OPEN_IMAGE : SocketAction.OPEN_VIDEO,
                               this.socketService.addresses.student,
                               this.testSelected?.idTest);
  }

  /**
   * Cierra el visor de imágenes
   */
  override closeViewer()
  {
    // //siempre notificamos al alumno independientemente del modo de interacción
    if(this.testSelected && (this.configService as HVitaConfigTeacherInterface).studentEnabled)
      this.socketService.send((this.testSelected.idTestType === TestType.IMAGE) ? SocketAction.CLOSE_IMAGE : SocketAction.CLOSE_VIDEO, this.socketService.addresses.student);

    super.closeViewer();
  }

  getFile()
  {
    return new Promise<string>((resolve) =>
    {
      const uri = this.ipcService.storageURL + '/' + ((this.testSelected?.idTestType === TestType.IMAGE) ? 'images' : 'videos') + '/' + this.testSelected?.file;
      resolve(uri);
    });
  }


  /**
   * Captura el evento play del video
   */
  playVideo()
  {
    //siempre notificamos al alumno independientemente del modo de interacción
    if((this.configService as HVitaConfigTeacherInterface).studentEnabled)
      this.socketService.send(SocketAction.PLAY_VIDEO, this.socketService.addresses.student);
  }

  /**
   * Captura el evento pause del video
   */
  pauseVideo()
  {
    //siempre notificamos al alumno independientemente del modo de interacción
    if((this.configService as HVitaConfigTeacherInterface).studentEnabled)
      this.socketService.send(SocketAction.PAUSE_VIDEO, this.socketService.addresses.student);
  }
}
