import { EventManagerService } from 'event-manager';
import { Injectable } from "@angular/core";
import { HVitaTemplateBaseService } from 'hvita-template';
import { HVitaConfigService } from "./hvita-config.service";
import { SocketAction, SocketMessage } from "hybrids-socket";
import { HVitaSocketService } from "./hvita-socket.service";
import { Field, OrderDirection, Query, RepositoryService, Table } from "sqlite";
import { Device } from "hybrids-config";
import { HVitaParameterService } from './hvita-parameter.service.';

/**
 * Servicio que controla el sistema de personalización
 * de visualización de parámetros de la simulación
 */
@Injectable({
  providedIn: 'root'
})
export class HVitaTemplateService extends HVitaTemplateBaseService
{
  constructor(configService:HVitaConfigService,
              eventManagerService:EventManagerService,
              private socketService:HVitaSocketService,
              private repositoryService: RepositoryService,
              private parameterService: HVitaParameterService)
  {
    super('hvita.teacher.template', configService, eventManagerService);
  }

  /**
   * Envía la lista de plantillas a Student
   * @param request SocketMessage
   */
  sendTemplateListStudent(request:SocketMessage)
  {
    this.socketService.send(request.action!, request.from!, this.templates, request.id);
  }

  /**
   * Carga las plantillas guardadas
   * @returns Promise<void>
   */
  loadTemplates(): Promise<void>
  {
    return new Promise<void>((resolve, reject) =>
    {
      try
      {
        let table = new Table('Templates',
        [
          new Field('isDefault', undefined, true, OrderDirection.DESC),
          new Field('name', undefined, true, OrderDirection.ASC)
        ]);

        //cargamos la lista completa de plantillas
        this.repositoryService.exeQuery(new Query([table])).then(templates =>
        {
          this.templates = templates;

          table = new Table('TemplatesSections');

          //completamos la plantilla por defecto
          this.repositoryService.exeQuery(new Query([table])).then(templatesSections =>
          {
            for (const template of this.templates)
              template.templatesSections = templatesSections.filter(m => m.idTemplate === template.idTemplate).cloneObject();

            table = new Table('TemplatesParameters');

            this.repositoryService.exeQuery(new Query([table])).then(templatesParameters =>
            {
              for (const template of this.templates)
                template.templatesParameters = templatesParameters.filter(m => m.idTemplate === template.idTemplate).cloneObject();

              //comprobamos si existe alguna plantilla guardada en memoria
              const localTemplate = this.getLocalTemplate();

              if(localTemplate)
              {
                this.template = localTemplate; //cargamos la plantilla de la memoria
              }
              else
              {
                this.template = this.templates.find(m => m.isDefault)?.cloneObject();
                this.template!.isDefault = false;
                this.saveLocalTemplate(false); //guardamos la plantilla actual para futuros usos
              }

              //creamos la distribución visual de elementos
              //los números de la clave corresponden con los del enumerador SectionType
              this.configService.config.distribution = this.getDistribution();

              resolve();
            });
          });
        });
      }
      catch (err)
      {
        reject();
      }
    });
  }

  /**
   * Guarda la plantilla de colores actual
   * @param name Nombre de la plantilla
   */
  async saveTemplate(name:string)
  {
    let fields:string[] = [ 'name', 'isDefault' ];
    let values:any[] = [ name, false ];

    const idTemplate = await this.repositoryService.insert('Templates', fields, values, 'idTemplate');

    for (const section of this.template!.templatesSections!)
    {
      fields = [ 'idTemplate', 'idSection', 'visible' ];
      values = [ idTemplate, section.idSection, section.visible ];

      await this.repositoryService.insert('TemplatesSections', fields, values);
    }

    for (const parameter of this.template!.templatesParameters!)
    {
      fields = [ 'idTemplate', 'idParameter', 'idColorText', 'idColorBackground', 'idColorLine', 'visible' ];
      values = [ idTemplate, parameter.idParameter, parameter.idColorText, parameter.idColorBackground, parameter.idColorLine, parameter.visible ];

      await this.repositoryService.insert('TemplatesParameters', fields, values);
    }

    await this.loadTemplates();

    this.template = this.templates.find(m => m.idTemplate === idTemplate);

    this.saveLocalTemplate(false);

    this.parameterService.parameters.map((parameter) =>
    {
      parameter.templateParameter = this.template!.templatesParameters!.find(m => m.idParameter === parameter.idParameter)?.cloneObject();
    });

    if((this.configService as HVitaConfigService).studentEnabled)
      this.socketService.send(SocketAction.TEMPLATES_LIST, this.socketService.addresses.student, this.templates);
  }

  /**
   * Elimina una plantilla guardada
   * @param idTemplate Identificador de plantilla que debe ser eliminado
   */
  async removeTemplate(idTemplate:number)
  {
    const index = this.templates.findIndex(m => m.idTemplate === idTemplate);
    const template = this.templates.splice(index, 1)[0];

    await this.repositoryService.delete('Templates', 'idTemplate', idTemplate);

    for (const section of template.templatesSections!)
      await this.repositoryService.delete('TemplatesSections', 'idTemplateSection', section.idTemplateSection!);

    for (const parameter of template.templatesParameters!)
      await this.repositoryService.delete('TemplatesParameters', 'idTemplateParameter', parameter.idTemplateParameter!);


    if(this.template!.idTemplate === idTemplate)
      this.saveLocalTemplate(true);
  }
}
