import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'loading',
    loadChildren: () => import('./pages/loading/loading.module').then(m => m.LoadingPageModule)
  },
  {
    path: 'age-mode',
    loadChildren: () => import('./pages/age-mode/age-mode.module').then(m => m.AgeModePageModule),
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule),
  },
  {
    path: 'error',
    loadChildren: () => import('./pages/error/error.module').then(m => m.ErrorPageModule)
  },
  {
    path: 'error/:type',
    loadChildren: () => import('./pages/error/error.module').then(m => m.ErrorPageModule)
  },
  {
    path: 'configuration',
    loadChildren: () => import('./pages/configuration/configuration.module').then(m => m.ConfigurationPageModule)
  },
  {
    path: 'scenarios/menu',
    loadChildren: () => import('./pages/scenarios/menu/menu.module').then(m => m.MenuPageModule)
  },
  {
    path: 'scenarios/edit-scenarios/:from',
    loadChildren: () => import('./pages/scenarios/edit-scenarios/edit-scenarios.module').then(m => m.EditScenariosPageModule)
  },
  {
    path: 'scenarios/edit-stages/:from/:scenario/:stage',
    loadChildren: () => import('./pages/scenarios/edit-stages/edit-stages.module').then(m => m.EditStagesPageModule)
  },
  {
    path: 'scenarios/edit-stages/:from/:flist/:scenario/:stage',
    loadChildren: () => import('./pages/scenarios/edit-stages/edit-stages.module').then(m => m.EditStagesPageModule)
  },
  {
    path: 'scenarios/import-export/menu',
    loadChildren: () => import('./pages/scenarios/import-export/menu/menu.module').then(m => m.MenuPageModule)
  },
  {
    path: 'scenarios/import-export/import',
    loadChildren: () => import('./pages/scenarios/import-export/import/import.module').then(m => m.ImportPageModule)
  },
  {
    path: 'scenarios/import-export/export',
    loadChildren: () => import('./pages/scenarios/import-export/export/export.module').then(m => m.ExportPageModule)
  },
  {
    path: 'simulation',
    loadChildren: () => import('./pages/simulation/simulation.module').then(m => m.SimulationPageModule)
  },
  {
    path: 'simulation/:scenario/:stage',
    loadChildren: () => import('./pages/simulation/simulation.module').then(m => m.SimulationPageModule)
  },
  {
    path: 'history',
    loadChildren: () => import('./pages/history/history.module').then(m => m.HistoryPageModule)
  },
  {
    path: 'calibration',
    loadChildren: () => import('./pages/calibration/calibration.module').then(m => m.CalibrationPageModule)
  },
  {
    path: 'wireless',
    loadChildren: () => import('./pages/wireless/wireless.module').then(m => m.WirelessPageModule)
  },
  {
    path: '',
    redirectTo: 'loading',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
