<div>
  <h2>{{ 'explicacion_escenario_titulo' | translate }}</h2>
  <p [innerHTML]="'explicacion_escenario_texto_1' | translate"></p>
  <h3>{{ 'explicacion_escenario_texto_2' | translate }}</h3>
  <ul>
    <li [innerHTML]="'explicacion_escenario_texto_3' | translate"></li>
    <li [innerHTML]="'explicacion_escenario_texto_4' | translate"></li>
    <li [innerHTML]="'explicacion_escenario_texto_5' | translate"></li>
  </ul>
  <h3>{{ 'explicacion_escenario_texto_6' | translate }}</h3>
  <ul>
    <li [innerHTML]="'explicacion_escenario_texto_7' | translate"></li>
    <li [innerHTML]="'explicacion_escenario_texto_8' | translate"></li>
    <li [innerHTML]="'explicacion_escenario_texto_9' | translate"></li>
  </ul>
  <p [innerHTML]="'explicacion_escenario_texto_10' | translate"></p>
  <p [innerHTML]="'explicacion_escenario_texto_11' | translate"></p>
</div>

