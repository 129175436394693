import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { HVitaDateFormatPipe } from "hvita-common";
import { HVitaScenarioService } from "./hvita-scenario.service";
import { HVitaHistoryBaseService } from "hvita-history";
import { HVitaLogService } from "./hvita-log.service";
import { Router } from "@angular/router";
import { DialogBoxData, DialogBoxService, DialogBoxType } from "dialog-box";
import { HVitaColorToNamePipe } from "hvita-color";
import { Info } from "info";
import { ManageFile } from "manage-file";
import { Usb } from "usb";
import { EventManagerService } from "event-manager";


/**
 * Gestiona los colores de la aplicación
 */
@Injectable({
  providedIn: 'root'
})
export class HVitaHistoryService extends HVitaHistoryBaseService
{
  constructor(scenarioService:HVitaScenarioService,
              logService:HVitaLogService,
              router:Router,
              dialogService:DialogBoxService,
              translateService:TranslateService,
              dateFormatPipe: HVitaDateFormatPipe,
              colorToNamePipe:HVitaColorToNamePipe,
              eventManagerService:EventManagerService)
  {
    super(scenarioService,
          logService,
          router,
          dialogService,
          translateService,
          dateFormatPipe,
          colorToNamePipe,
          eventManagerService);
  }

  /**
   * Exporta la lista de actividad como un fichero txt
   */
  export()
  {
    return new Promise<void>(async (resolve) =>
    {
      if(this.selectedSessions.length > 0)
        {
          if(await Info.isProduction() && !await Usb.hasDevice())
          {
            this.dialogService.show(new DialogBoxData({
              type: DialogBoxType.ALERT,
              message: 'inserte_usb',
              warning: true
            }));
          }
          else
          {
            this.composeFile().then(async file =>
            {
              ManageFile.save(file, await Usb.unit(), 'activity')
              .then(() =>
              {
                this.dialogService.show(new DialogBoxData({
                  type: DialogBoxType.ALERT,
                  message: 'exportacion_correcta'
                }));
              })
              .catch(err =>
              {
                if(err === 'error')
                {
                  this.dialogService.show(new DialogBoxData({
                    type: DialogBoxType.ALERT,
                    message: 'exportacion_error',
                    warning: true
                  }));
                }
              })
              .finally(() => resolve());
            });
          }
        }
    });
  }
}
