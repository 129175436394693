import { registerPlugin } from '@capacitor/core';

import type { ServerPlugin } from './definitions';

/**
 * Registra el plugin
 */
const Server = registerPlugin<ServerPlugin>('Server', {
  web: () => import('./web').then(m => new m.ServerWeb()),
  electron: () => (window as any).CapacitorCustomPlatform.plugins.Server,
});

export * from './definitions';
export { Server };
