import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpBackend, HttpClientModule } from '@angular/common/http';
import { ElectronService } from 'ngx-electron';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { DialogBoxConfig, DialogBoxModule } from 'dialog-box';
import { VersionBoxModule, VersionBoxConfig } from 'version-box';
import { KeyboardModule, KeyboardConfig } from 'keyboard';
import { WirelessModule } from 'wireless';
import { SqliteModule } from 'sqlite';
import { ModalBoxButton, ModalBoxClose, ModalBoxEvent, ModalBoxModule } from 'modal-box';
import { ModalModule } from 'modal';
import { HYBRIDS_SOCKET_SERVICE_TOKEN, HybridsSocketModule } from 'hybrids-socket';
import { environment } from 'src/environments/environment';
import { Distribution, HVitaConfig, HVitaDateFormatPipe, HVitaNumberToTimePipe, HVitaParameterToValuePipe, HVitaCommonModule, ReplicaMode, SectionType, VentilationMode, HVITA_CONFIG_SERVICE_TOKEN } from 'hvita-common';
import { HttpManagerConfig, HttpManagerModule } from 'http-manager';
import { HttpStorageConfig, HttpStorageModule } from 'http-storage';
import { LoadingModule, LoadingConfig } from 'loading';
import { ApplicationType } from 'hybrids-config';
import { HVitaConfigService } from './services/hvita-config.service';
import { HVitaSocketService } from './services/hvita-socket.service';
import { HVitaColorService } from './services/hvita-color.service';
import { HVitaScenarioService } from './services/hvita-scenario.service';
import { HVitaLogService } from './services/hvita-log.service';
import { HVitaSimulationService } from './services/hvita-simulation.service';
import { HVitaTemplateService } from './services/hvita-template.service';
import { HVitaBatteryModule } from 'hvita-battery';
import { HVITA_COLOR_SERVICE_TOKEN, HVitaColorToNamePipe } from 'hvita-color';
import { HVitaSimulationSelectorModule } from 'hvita-simulation-selector';
import { HVitaParameterService } from './services/hvita-parameter.service.';
import { HVitaTestsService } from './services/hvita-tests.service';
import { HVITA_MONITOR_SERVICE_TOKEN, HVITA_WAVES_SERVICE_TOKEN, PatientMonitorModule } from 'patient-monitor';
import { PatientMonitorService } from './services/patient-monitor.service';
import { PatientMonitorWaveService } from './services/patient-monitor-wave.service';
import { HVitaEditionService } from './services/hvita-edition.service';
import { HVitaEditionInfoBoxComponentModule } from './components/hvita-edition-info-box/hvita-edition-info-box.component.module';
import { HVITA_EDITION_SERVICE_TOKEN } from 'hvita-edition';
import { HVITA_LOG_SERVICE_TOKEN } from 'hvita-log';
import { HVITA_PARAMETER_SERVICE_TOKEN } from 'hvita-parameter';
import { HVITA_SCENARIO_SERVICE_TOKEN } from 'hvita-scenario';
import { HVITA_SIMULATION_SERVICE_TOKEN } from 'hvita-simulation';
import { HVITA_TEMPLATE_SERVICE_TOKEN } from 'hvita-template';
import { HVITA_TESTS_SERVICE_TOKEN } from 'hvita-tests';
import { HVitaLanguageService } from './services/hvita-language.service';
import { HVITA_LANGUAGE_SERVICE_TOKEN } from 'hvita-language';
import { HVitaMenuComponentModule } from './components/hvita-menu/hvita-menu.module';
import { HVITA_HISTORY_SERVICE_TOKEN } from 'hvita-history';
import { HVitaHistoryService } from './services/hvita-history.service';

//inicializamos los prototype de objetos globales
import { global } from 'common';

global.init();

const config = new HVitaConfig({
  me: environment.device,
  endPointSocket: environment.endPointSocket,
  version: environment.version,
  defaultLanguage: environment.defaultLanguage,
  applicationType: ApplicationType.HVITA,

  replicaMode: ReplicaMode.STANDALONE,
  ventilationMode:VentilationMode.ACV,

  maxOcclusion: 100,
  minOcclusion: 0,
  stepOcclusion: 2,

  distribution: new Array<Distribution>(
    new Distribution({
      section: SectionType.ECMO_PREASURE,
      title : 'ecmo',
      keys : [['lpm', 'rpm'], ['pven', 'part'], ['ap', 'tven']]
    }),
    new Distribution({
      section: SectionType.ECMO_FLOW,
      title : 'ecmo',
      keys : [['svo2', 'hbhc'], ['act', ''], ['gasflow', 'gasfio2']]
    }),
    new Distribution({
      section: SectionType.VENTILATION,
      title : 'respirador',
      keys : [['frpautado', 'peep', 'frtotal'], ['fio2', 'vtespirado', 'pplat'], ['vtpautado', 'ps', 'ie'], ['pins', 'ppeak', '']]
    }),
    new Distribution({
      section: SectionType.NEURO,
      title : 'neuro',
      keys : [['bis', 'nirs']]
    }),
    new Distribution({
      section: SectionType.MONITOR,
      title : 'monitor',
      keys : [['ecg', 'abp', 'pap', 'spo2', 'cvp']]
    }),
    new Distribution({
      section: SectionType.CONTROLLER,
      title : 'controlador',
      keys : [['p3', 'p1'], ['preox', 'postox'], ['bubble', 'bleed']]
    })
  )
});

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot({animated: false}),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend]
      }
    }),
    AppRoutingModule,
    ModalModule.forRoot(),
    ModalBoxModule.forRoot(),
    DialogBoxModule.forRoot(new DialogBoxConfig({
      accept: new ModalBoxButton({
        title: 'aceptar',
        event: ModalBoxEvent.ACCEPT
      }),
      cancel: new ModalBoxButton({
        title: 'cancelar',
        event: ModalBoxEvent.CANCEL
      })
    })),
    VersionBoxModule.forRoot(new VersionBoxConfig({
      close: new ModalBoxClose({
        event: ModalBoxEvent.CANCEL,
        icon: './hvita/assets/images/SVG/close_enabled.svg'
      }),
      image: './hvita/assets/images/SVG/isotype.svg'
    })),
    SqliteModule.forRoot({file: '../assets/database/hvita.json'}),
    WirelessModule.forRoot(),
    KeyboardModule.forRoot(new KeyboardConfig({ active: true })),
    HybridsSocketModule.forRoot(),
    HVitaCommonModule.forRoot(config),

    HttpManagerModule.forRoot(new HttpManagerConfig({
      defaultLanguage: environment.defaultLanguage
    })),
    HttpStorageModule.forRoot(new HttpStorageConfig({})),
    LoadingModule.forRoot(new LoadingConfig({})),

    HVitaBatteryModule,
    HVitaSimulationSelectorModule.forRoot(),
    HVitaMenuComponentModule,
    PatientMonitorModule.forRoot({ numViews: 4 })
  ],
  providers: [
    HVitaNumberToTimePipe,
    HVitaParameterToValuePipe,
    HVitaDateFormatPipe,
    HVitaColorToNamePipe,
    HVitaEditionInfoBoxComponentModule,
    ElectronService,
    { provide: HVITA_CONFIG_SERVICE_TOKEN, useExisting: HVitaConfigService },
    { provide: HYBRIDS_SOCKET_SERVICE_TOKEN, useExisting: HVitaSocketService },
    { provide: HVITA_COLOR_SERVICE_TOKEN, useExisting: HVitaColorService },
    { provide: HVITA_SCENARIO_SERVICE_TOKEN, useExisting: HVitaScenarioService },
    { provide: HVITA_LOG_SERVICE_TOKEN, useExisting: HVitaLogService },
    { provide: HVITA_TEMPLATE_SERVICE_TOKEN, useExisting: HVitaTemplateService },
    { provide: HVITA_SIMULATION_SERVICE_TOKEN, useExisting: HVitaSimulationService },
    { provide: HVITA_PARAMETER_SERVICE_TOKEN, useExisting: HVitaParameterService },
    { provide: HVITA_TESTS_SERVICE_TOKEN, useExisting: HVitaTestsService },
    { provide: HVITA_MONITOR_SERVICE_TOKEN, useExisting: PatientMonitorService },
    { provide: HVITA_WAVES_SERVICE_TOKEN, useExisting: PatientMonitorWaveService },
    { provide: HVITA_EDITION_SERVICE_TOKEN, useExisting: HVitaEditionService },
    { provide: HVITA_LANGUAGE_SERVICE_TOKEN, useExisting: HVitaLanguageService },
    { provide: HVITA_HISTORY_SERVICE_TOKEN, useExisting: HVitaHistoryService },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule
{
}

export function HttpLoaderFactory(http: HttpBackend) {
  return new MultiTranslateHttpLoader(http, [
     { prefix: './hvita/assets/i18n/', suffix: '.json' }, //hvita assets
     { prefix: './keyboard/assets/i18n/', suffix: '.json' }, //keyboard assets
     { prefix: './wireless/assets/i18n/', suffix: '.json' }, //wireless assets
     { prefix: './modal-box/assets/i18n/', suffix: '.json' }, //modal-box assets
     { prefix: './version-box/assets/i18n/', suffix: '.json' }, //version-box assets
     { prefix: './patient-monitor/assets/i18n/', suffix: '.json' } //patient-monitor assets
   ])
 }
