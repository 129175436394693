
import { ElectronService } from 'ngx-electron';
import { Component, Inject } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AppBase, ErrorType } from 'hybrids-config';
import { HVitaLanguageService } from './services/hvita-language.service';
import { DatabaseService, RepositoryService } from 'sqlite';
import { HVitaIpcService } from './services/hvita-ipc.service';
import { VersionBoxService } from 'version-box';
import { HVitaConfigService } from './services/hvita-config.service';
import { Network } from 'network';
import { HVitaSocketListenerService } from './services/hvita-socket-listener.service';
import { HVitaSocketService } from './services/hvita-socket.service';
import { HVitaTemplateService } from './services/hvita-template.service';
import { HVitaColorService } from './services/hvita-color.service';
import { HVitaTestsService } from './services/hvita-tests.service';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';


/**
 * Componente que representa la base de la aplicación
 */
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent extends AppBase
{
  environment = environment;

  constructor(private electronService:ElectronService,
              private ipcService:HVitaIpcService,
              private configService:HVitaConfigService,
              private databaseService:DatabaseService,
              private repositoryService: RepositoryService,
              private languageService:HVitaLanguageService,
              private versionBoxService:VersionBoxService,
              private socketService:HVitaSocketService,
              private socketListenerService:HVitaSocketListenerService,
              private templateService:HVitaTemplateService,
              private colorService:HVitaColorService,
              private testsService:HVitaTestsService,
              private router:Router,
              @Inject(DOCUMENT) doc:Document)
  {
    super(doc);
  }

  /**
   * Ejecuta los procesos de inicialización de la aplicación
   * y añade las subscripciones necesarias para responder a
   * diversas acciones emitidas desde distintos componentes
   */
  async initialize()
  {
    try
    {
      this.repositoryService.setDatabase(await this.databaseService.openConnection());
      this.languageService.initialize();

      await this.ipcService.initialize();

      this.versionBoxService.initialize(this.ipcService.info.software, this.ipcService.info.hardware);

      this.configService.initialize();

      this.templateService.initialize();

      this.testsService.initialize();

      this.colorService.initialize().then(async () =>
      {
        //inicializamos el socket y el listener
        this.socketService.initialize(environment.endPointSocket, environment.device);
        this.socketListenerService.initialize();

        //iniciamos la conexion a red wifi y socket
        await Network.initialize(this.ipcService.info.ssid!, this.ipcService.info.password!, this.ipcService.localStorageWireless);

        if(this.electronService.isElectronApp) //en aplicaciones electron iniciamos el checkeo de redes
          await Network.check();
        else //en aplicaciones web abrimos la conexión socket
          this.socketService.openConnection();
      });
    }
    catch(err)
    {
      this.router.navigate(['/', 'error', ErrorType.UNKNOW]);
    }
  }
}
