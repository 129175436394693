import { Component} from '@angular/core';

/**
 * Muestra un modal con un mensaje de información
 */
@Component({
  selector: 'hvita-edition-info-box',
  templateUrl: './hvita-edition-info-box.component.html',
  styleUrls: ['./hvita-edition-info-box.component.scss'],
})
export class HVitaEditionInfoBoxComponent
{
  constructor() { }

}
