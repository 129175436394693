import { ElectronService } from 'ngx-electron';
import { EventEmitter, Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { Usb } from 'usb';
import { DllDevice } from 'info';
import { DialogBoxService } from 'dialog-box';
import { HybridsIpcBaseService } from 'hybrids-ipc';
import { Server } from 'server';
import { HVitaSocketService } from './hvita-socket.service';

/**
 * Gestiona la comunicación con los componentes de Electron
 */
@Injectable({
  providedIn: 'root'
})
export class HVitaIpcService extends HybridsIpcBaseService
{
  private exportEmitter = new EventEmitter<string>();
  private importEmitter = new EventEmitter<string>();
  private deflateEmitter = new EventEmitter<string>();

  constructor(dialogService: DialogBoxService,
              socketService: HVitaSocketService,
              electronService: ElectronService)
  {
    super(DllDevice.HVITA_TEACHER, 'hvita.teacher.wireless', dialogService, electronService, socketService)

    //si es una aplicación Electron añadimos los observables
    //de recepción de eventos
    if(electronService.isElectronApp)
    {
      //reciben respuesta de una petición previa
      this._window.api.receive("export", (result:any) => this.exportEmitter.emit(result));
      this._window.api.receive("import", (result:any) =>this.importEmitter.emit(result));
      this._window.api.receive("deflate", (result:any) =>this.deflateEmitter.emit(result));
    }
  }

  /**
   * Ejecuta una serie de acciones después de la conexión socket que sobreescriben el método en la clase base
   */
  protected setNetworkConnectedActions(): void
  {
    Server.enable().then(url => this.storageURL = url).catch(() => {});
  }

  /**
   * Ejecuta una serie de acciones después de la desconexión socket que sobreescriben el método en la clase base
   */
  protected override setNetworkDisonnectedActions(): void
  {
    Server.disable().then(url => this.storageURL = url).catch(() => {});
  }

  /**
   * Ejecuta una serie de acciones después de la inicialización del socket que sobreescriben el método en la clase base
   */
  protected setInitializeDataActions(): void
  {
    //obtenemos la url de almacenamiento de ficheros
    Server.enable().then(url => this.storageURL = url).catch(() => {});
  }

  /**
   * Exporta los datos enviados a un fichero .med (es un txt con la extensión modificada)
   * @param data datos a exportar
   * @returns Promise<void>
   */
  export(data:any): Promise<void>
  {
    return new Promise(async (resolve, reject) =>
    {
      try
      {
        if(this.electronService.isElectronApp)
        {
          //enviamos orden al api para que exporte los datos
          const res = await this._window.api.send("export", { data: data, drive: await Usb.unit() });

          //esperamos respuesta del api
          const subs:Subscription = this.exportEmitter.subscribe((result) =>
          {
            subs.unsubscribe();

            //en función de la respuesta del api devolvemos un resultado
            if(result === 'saved')
              resolve();
            else
              reject(result);
          });
        }
        else
        {
          resolve();
        }
      }
      catch
      {
        reject('error');
      }
    });
  }

  /**
   * Importa los datos contenidos en un fichero .med (es un zip con la extensión modificada)
   * @returns Promise<string>
   */
  import(): Promise<any>
  {
    return new Promise(async (resolve, reject) =>
    {
      try
      {
        if(this.electronService.isElectronApp)
        {
          //enviamos orden al api para que importe los datos
          this._window.api.send("import", await Usb.unit());

          //esperamos respuesta del api
          const subs:Subscription = this.importEmitter.subscribe((result) =>
          {
            subs.unsubscribe();

            //en función de la respuesta del api devolvemos un resultado
            if(result !== 'error' && result !== 'canceled')
              resolve(result);
            else
              reject(result);
          });
        }
        else
        {
          resolve('');
        }
      }
      catch
      {
        reject('error');
      }
    });
  }

  /**
   * Descomprime los ficheros seleccionados de un fichero zip
   */
  async deflate(data: any): Promise<string>
  {
    return new Promise((resolve, reject) =>
    {
      try
      {
        if(this.electronService.isElectronApp)
        {
          //enviamos orden al api para que descomprima las imágenes de los tests
          this._window.api.send("deflate", data);

          //esperamos respuesta del api
          const subs:Subscription = this.deflateEmitter.subscribe((result) =>
          {
            subs.unsubscribe();

            //en función de la respuesta del api devolvemos un resultado
            if(result !== 'error')
              resolve(result);
            else
              reject(result);
          });
        }
        else
        {
          resolve('');
        }
      }
      catch
      {
        reject('error');
      }
    });
  }
}
