import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HVitaConfig, Language } from 'hvita-common';
import { HVitaLanguageTeacherService } from 'hvita-language';
import { Query, RepositoryService, Table } from 'sqlite';
import { HVitaSocketService } from './hvita-socket.service';
import { HVitaConfigService } from './hvita-config.service';


/**
 * Gestiona los colores de los parámetros de simulación
 */
@Injectable({
  providedIn: 'root'
})
export class HVitaLanguageService extends HVitaLanguageTeacherService
{
  constructor(configService:HVitaConfigService,
              translateService:TranslateService,
              hybridsSocketService: HVitaSocketService,
              private repositoryService:RepositoryService)
  {
    super(configService, translateService, hybridsSocketService);
  }

  /**
   * Obtiene los idiomas disponibles para los dispositivos (Teacher o Student)
   * y el establece idioma seccionado para cada dispositivo.
   * Además, setea el componente translate para mostrar los textos en el idioma
   * seleccionado para Teacher.
   * @returns Promise<void>
   */
  initialize() : Promise<void>
  {
    return new Promise<void>((resolve, reject) =>
    {
      try
      {
        this.repositoryService.exeQuery(new Query([new Table('Languages')])).then((languages:Language[]) =>
        {
          this.setAvailableLanguages(languages);

          resolve();
        });
      }
      catch (err)
      {
        reject(err);
      }
    });
  }
}
